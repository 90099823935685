import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import nodeModule from "./modules/node";

Vue.use(Vuex);

const isDevMode = process.env.NODE_ENV !== "production";

const commonPlugins = [];
const devPlugins = [createLogger(), ...commonPlugins];

const state = {};

const plugins = isDevMode ? devPlugins : commonPlugins;
const modules = {
  node: nodeModule,
};

const store = new Vuex.Store({
  state,
  modules,
  plugins,
});

export default store;
