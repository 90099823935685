var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{ref:"header",class:[
    _vm.$style['web-mb-header'],
    { [_vm.$style['web-mb-header--is-sticky']]: _vm.isSticky },
  ]},[_c('ContainerComponent',{style:({ maxWidth: 'none' })},[_c('div',{class:_vm.$style['web-mb-header__panel']},[_c('ContainerComponent',[_c('div',{class:_vm.$style['web-mb-header__row']},[_c('a',{staticClass:"selenium-header-menu-list-href-logo",attrs:{"href":"/"}},[_c('ImgComponent',{attrs:{"src":_vm.logoImg,"alt":"VIVA Money - Instant Personal Loan App","img-style":{ width: '85px', height: 'auto' }}})],1),_c('nav',{class:_vm.$style['web-mb-header__nav']},[_vm._l((_vm.routes),function(route){return [(route.nav.header.show)?_c('div',{key:route.name,class:[
                  {
                    [_vm.$style['web-mb-header__active-page']]:
                      route.path === _vm.$route.path,
                  },
                ]},[_c('a',{class:route.nav.header.class,attrs:{"href":route.path}},[_vm._v(_vm._s(route.title))])]):_vm._e()]})],2),_c('div',{class:[
              _vm.$style['web-mb-header__mb-nav-icon'],
              { [_vm.$style['web-mb-header__mb-nav-icon--is-open']]: _vm.isOpen },
            ],on:{"click":_vm.toggleMenu}})]),_c('nav',{class:[
            _vm.$style['web-mb-header__row'],
            _vm.$style['web-mb-header__mb-nav'],
            { [_vm.$style['web-mb-header__mb-nav--is-open']]: _vm.isOpen },
          ]},[_vm._l((_vm.routes),function(route){return [(route.nav.header.show)?_c('div',{key:route.name,class:[
                {
                  [_vm.$style['web-mb-header__active-page']]:
                    route.path === _vm.$route.path,
                },
              ]},[_c('a',{attrs:{"href":route.path}},[_vm._v(_vm._s(route.title))])]):_vm._e()]})],2)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }