import instancesFolders from "@/instances";
const getCurrentInstanceConfig = () => {
  if (!process.env.VUE_APP_PROJECT_REGION) {
    console.warn("No VUE_APP_PROJECT_REGION env, default region will be used");
  }

  const defaultFolder = instancesFolders[Object.keys(instancesFolders)[0]];
  const instanceLocalName = instancesFolders[process.env.VUE_APP_PROJECT_REGION];
  const folderName = instanceLocalName || defaultFolder;

  if (!folderName) throw new Error("Create instance error! Check vue.config.js file.");

  return {
    currentInstanceLocalName: folderName,
    allowedLocalNames: Object.values(instancesFolders),
    folderName,
  };
};

export default getCurrentInstanceConfig;
