<template>
  <div v-if="isOpen" :class="$style['web-mb-modal']">
    <div :class="$style['web-mb-modal__wrapper']">
      <div :class="$style['web-mb-modal__container']">
        <div :class="$style['web-mb-modal__header']">
          <div :class="$style['web-mb-modal__header-title']">
            <slot name="header">default header</slot>
          </div>

          <button
            :class="$style['web-mb-modal__header-close-btn']"
            @click="close"
          />
        </div>

        <div :class="$style['web-mb-modal__body']">
          <slot name="body">default body</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" module>
.web-mb-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: table;

  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__container {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    padding: 1.5rem 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-title {
    text-align: center;
    flex: 1;
    font-size: 1.375rem;
    font-weight: 600;
  }

  &__header-close-btn {
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    background-image: url(../../../assets/icons/close-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
  }

  @media (min-width: $min-lg) {
    &__container {
      position: initial;
      margin: 0px auto;
      width: 700px;
      border-radius: 2.5rem;
      padding: 3.5rem 8rem;
    }

    &__header-title {
      text-align: initial;
    }
  }
}
</style>
