<template>
  <PanelComponent loading></PanelComponent>
</template>

<script>
import PanelComponent from "@/components/app/ui/PanelComponent.vue";

export default {
  name: "LoaderComponent",
  components: {
    PanelComponent,
  },
};
</script>
