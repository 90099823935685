import widgets from "@/components/app/widgets";
import removeHtmlTagsFromString from "@/utils/removeHtmlTagsFromString";

const createScreenFromNode = async ({ commit, dispatch }, { node }) => {
  const currentScreen = node?.screens?.[0];
  const screenFields = currentScreen?.nodeFields;
  
  const initNodeData = await dispatch("getInitialNodeData", screenFields) || {};

  commit("setCurrentScreen", currentScreen);
  dispatch("setScreenData", { data: initNodeData });
  dispatch("formatScreenWidgets", {
    screenWidgetsList: currentScreen?.widgets || [],
  });
};

const setScreenData = ({ commit }, { data }) => {
  commit("setScreenData", data);
};

// todo: state, commit, getters
const formatScreenWidgets = (
  { commit, getters },
  { screenWidgetsList = [] }
) => {
  if (!getters.screenComponent) return {};

  const formattedWidgets = {};

  screenWidgetsList.forEach((widget) => {
    const widgetComponent = widgets[widget?.name] || {};

    formattedWidgets[widget?.name] = {
      ...widget,
      ...widgetComponent,
    };
  });

  commit("setScreenWidgets", formattedWidgets);
};

const getInitialNodeData = (_, screenFields = []) => {
  const initialNodeData = {};
  
  screenFields.forEach((field) => {
    // Juicy fields also trigger this condition :)
    if (field?.nodeType !== "input") return;
    
    const name = field?.name;
    const data = removeHtmlTagsFromString(field?.properties?.text);
    
    if (!data) return;
    
    initialNodeData[name] = data;
  });
  
  return initialNodeData;
};

const processingScreenUpdate = async (store) => {
  try {
    const screenProperties = store.getters["screenProperties"] || {};
    const currentInterval = store.state.updateCurrentScreenInterval;
    
    const isUpdatableScreen = Object.hasOwn(screenProperties, "needUpdate");
    const needUpdate = screenProperties?.needUpdate?.toLowerCase() === "true";
    
    if (!isUpdatableScreen && !currentInterval) return;

    if (!isUpdatableScreen && currentInterval) {
      return store.dispatch("removeScreenUpdateInterval");
    }

    if (isUpdatableScreen && needUpdate && currentInterval) {
      store.dispatch("removeScreenUpdateInterval");
    }

    if (needUpdate) {
      // "5" -> 5000(ms)
      const updatePeriod = parseInt(screenProperties?.updatePeriod) * 1000;
      const updateAction = screenProperties?.updateAction || "next";

      const executor = async () => {
        await store.dispatch("node/transitionToNode", {
          destination: updateAction,
          useBackgroundSync: true,
        }, { root: true });
      };

      store.dispatch("createScreenUpdateInterval", {
        executor,
        updatePeriod,
      });
    }
  } catch (e) {
    console.error(e)
  }
};

const removeScreenUpdateInterval = (store) => {
  const interval = store.state.updateCurrentScreenInterval;
  
  if (interval) {
    clearInterval(interval);
    store.commit("setUpdateCurrentScreenInterval", null);
  }
};

const createScreenUpdateInterval = (store, { executor, updatePeriod }) => {
  if (!executor || !updatePeriod) return;
  
  const interval = setInterval(async () => {
    await executor();
  }, updatePeriod);
  
  store.commit("setUpdateCurrentScreenInterval", interval);
};

export default {
  createScreenFromNode,
  setScreenData,
  formatScreenWidgets,
  getInitialNodeData,
  createScreenUpdateInterval,
  removeScreenUpdateInterval,
  processingScreenUpdate,
};
