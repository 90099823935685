const widgets = {
  widgetWebTariffsWidget: {
    component: () => import("@/components/app/widgets/WebTariffsWidget.vue"),
  },
  widgetWebNextButton: {
    component: () => import("@/components/app/widgets/WebNextButtonWidget.vue"),
  },
  widgetWebButtonArrow: {
    component: () =>
      import("@/components/app/widgets/WebButtonArrowWidget.vue"),
  },
  widgetSlyderCard: {
    component: () => import("@/components/app/widgets/SliderCardWidget.vue"),
  },
};

export default widgets;
