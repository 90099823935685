<template>
  <!-- SLIDES -->
  <SectionComponent is-wider>
    <ContainerComponent is-wider>
      <SectionHeaderComponent is-wider>
        <HeadingComponent level="h2"
          >Steps to apply for Credit Line</HeadingComponent
        >
      </SectionHeaderComponent>
      <VueSlickCarousel
        v-bind="carouselSettings"
        ref="slides-carousel"
        @afterChange="afterChange"
      >
        <div v-for="slide in slides" :key="slide.id">
          <SectionPanelComponent
            :class="[
              $style['web-mb-slides-section__panel'],
              'selenium-main-block-steps-to-apply-slides',
            ]"
          >
            <div :class="$style['web-mb-slides-section__row']">
              <div
                :class="[
                  $style['web-mb-slides-section__col'],
                  $style['web-mb-slides-section__img-wrap'],
                ]"
              >
                <ImgComponent
                  :src="slide.img"
                  :img-style="{
                    width: 'auto',
                    height: '400px',
                    filter: 'drop-shadow(-8px 8px 24px #999)',
                  }"
                  loading="lazy"
                  alt="Steps to Apply for Personal loan from VIVA Money Line of Credit App. Enjoy an easy & hassle-free process of taking interest free loans."
                />
              </div>
              <div
                :class="[
                  $style['web-mb-slides-section__col'],
                  $style['web-mb-slides-section__content-wrap'],
                ]"
              >
                <div :class="$style['web-mb-slides-section__content']">
                  <HeadingComponent level="h2" not-semantic>{{
                    slide.title
                  }}</HeadingComponent>
                  <p>{{ slide.text }}</p>
                </div>
                <div
                  :class="[
                    $style['web-mb-slides-section__nums'],
                    'selenium-main-block-steps-to-apply-slides-section-numbers',
                  ]"
                >
                  <div
                    v-for="n in slides.length"
                    :key="n"
                    :class="[
                      {
                        [$style['web-mb-slides-section__current-slide']]:
                          n === slide.id,
                      },
                      $style['web-mb-slides-section__num'],
                    ]"
                    @click="showNext(n - 1)"
                  >
                    {{ n }}
                  </div>
                </div>
              </div>
            </div>
          </SectionPanelComponent>
        </div>
      </VueSlickCarousel>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import SectionComponent from "@/components/ui/SectionComponent.vue";
import SectionPanelComponent from "@/components/ui/SectionPanelComponent.vue";
import SectionHeaderComponent from "@/components/ui/SectionHeaderComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";

import HeadingComponent from "@/components/ui/elements/HeadingComponent.vue";

import VueSlickCarousel from "vue-slick-carousel";

import slideImg1 from "@/assets/images/slides/slide-1-img.webp";
import slideImg2 from "@/assets/images/slides/slide-2-img.webp";
// import slideImg3 from "@/assets/images/slides/slide-3-img.webp";
import slideImg4 from "@/assets/images/slides/slide-4-img.webp";
import slideImg5 from "@/assets/images/slides/slide-5-img.webp";
// import slideImg6 from "@/assets/images/slides/slide-6-img.webp";

export default {
  name: "SlidesSection",
  components: {
    ContainerComponent,
    SectionComponent,
    SectionPanelComponent,
    SectionHeaderComponent,
    ImgComponent,
    HeadingComponent,
    VueSlickCarousel,
  },
  data: () => {
    return {
      // json
      slides: [
        {
          id: 1,
          title: "Apply for the Credit Line",
          text: "One form, for instant credit line limit approval",
          img: slideImg1,
        },
        {
          id: 2,
          title: "KYC Verification & Digital Contract Signing",
          text: "Just a selfie or a quick video call, then sign the loan agreement digitally, with zero paperwork",
          img: slideImg2,
        },
        // {
        //   id: 3,
        //   title: "Digital signature",
        //   text: "Sign the loan agreement digitally, with zero paper work",
        //   img: slideImg3,
        // },
        {
          id: 3,
          title: "Start withdrawing!",
          text: "Choose how much you want to withdraw and get it transferred directly to your account",
          img: slideImg4,
        },
        {
          id: 4,
          title: "Easy repayments, your way",
          text: "Want to pay 0 interest? Repay the money within the Grace Period. Or, pay with easy EMIs of 5, 10 or 20 months",
          img: slideImg5,
        },
        // {
        //   id: 6,
        //   title: "Get upgraded",
        //   text: "We'll always get you more. Your credit limit can be auto-increased after you repay the borrowed amount!",
        //   img: slideImg6,
        // },
      ],
      carouselSettings: {
        dots: true,
        dotsClass:
          "slick-dots custom-dot-class selenium-main-block-steps-to-apply-slides-section-dots",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      btn1: null,
      btn2: null,
    };
  },
  mounted() {
    this.btn1 = this.$refs["slides-carousel"].$el.getElementsByClassName(
      "slick-arrow slick-prev"
    )[0];
    this.btn2 = this.$refs["slides-carousel"].$el.getElementsByClassName(
      "slick-arrow slick-next"
    )[0];

    this.btn1.classList.add(
      "selenium-main-block-steps-to-apply-button-arrow-prev"
    );
    this.btn2.classList.add(
      "selenium-main-block-steps-to-apply-button-arrow-next"
    );
  },
  methods: {
    showNext(index) {
      this.$refs["slides-carousel"].goTo(index);
    },
    afterChange() {
      this.btn1.classList.add(
        "selenium-main-block-steps-to-apply-button-arrow-prev"
      );
      this.btn2.classList.add(
        "selenium-main-block-steps-to-apply-button-arrow-next"
      );
    },
  },
};
</script>

<style lang="scss" module>
.web-mb-slides-section {
  &__panel {
    margin: 0 0.5rem;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    height: 100%;
  }

  &__col {
    flex: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    justify-content: center;

    h2 {
      font-size: 1.75rem;
      padding: 0 2rem;
    }
  }

  &__content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
  }

  &__img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
  }

  &__current-slide {
    color: #b51ace;
  }

  &__nums {
    display: flex;
    justify-content: space-around;
    // gap: 1rem;
    color: #a0a0a0;
    // padding-top: 4rem;
  }

  &__num {
    cursor: pointer;
  }

  @media (min-width: $min-md) {
    &__row {
      flex-direction: row;
      // align-items: center;
      text-align: initial;
    }

    &__content {
      gap: 1.5rem;

      h2 {
        font-size: 2rem;
        padding: 0;
      }
    }

    &__nums {
      justify-content: start;
      padding-top: 0;
      gap: 2rem;
    }

    &__img-wrap {
      padding-top: 0;
    }
  }
}
</style>
