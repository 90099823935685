import axios from "axios";
import { jwtDecode } from "jwt-decode";

import getJuicyScoreParams from "@/utils/getJuicyScoreParams";

export const HOST_URL = process.env?.VUE_APP_SERVICE_URL;
export const baseServiceUrl = process.env?.VUE_APP_SERVICE_V1_URL;

const projectSettings = {
  deviceId: null,
  projectId: process.env.VUE_APP_PROJECT_ID || "web_process",
  lang: process.env.VUE_APP_LANG || "en",
};

// Г-безопасность. Используем замыкание для хранения токенов
let access_token = "";
let refresh_token = "";
let csrf_token = "";

export const baseConfig = {
  baseURL: baseServiceUrl,
  responseType: "json",
  responseEncoding: "utf8",
};

// No abstract classes?
class API {
  constructor({
    useJuicyScore = false,
    baseURL = baseConfig.baseURL,
  }) {
    this.instance = axios.create({ ...baseConfig, baseURL });
    this.commonRequestData = projectSettings;
    this.juicyData = {};
    this._useJuicyScore = useJuicyScore;

    if (this.constructor === API) {
      throw new Error("Abstract classes can't be instantiated.");
    }
  }
  
  // Private method
  generateDeviceId() {
    // Copium
    return (Math.random() + 1).toString(36).substring(2);
  };
  
  createDeviceId() {
    if (this.commonRequestData.deviceId) return;
    
    // Возможно нужно будет сохранять в localStorage
    this.commonRequestData.deviceId = this.generateDeviceId();
  }
  
  decodeJwtToken(token) {
    if (!token) return null;
    
    return jwtDecode(token);
  }
  
  checkAccessTokenIsExpired() {
    const currentTime = new Date().getTime();
    const { exp } = this.decodeJwtToken(access_token);
    
    return (exp * 1000) - currentTime <= 10000;
  }

  checkRefreshTokenIsExpired() {
    const currentTime = new Date().getTime();
    const { exp } = this.decodeJwtToken(refresh_token) * 1000;

    return exp - currentTime <= 10000;
  }

  // TODO: add disable juicy flag
  async initJuicyParams() {
    if (this.juicyData?.juicyVersion) return;

    try {
      const juicyParams = await getJuicyScoreParams() || {};

      this.juicyData = { ...this.juicyData, ...juicyParams };
    } catch (e) {
      console.error("JuicyScore init error:", e);
    }
  }

  async fetchCsrfToken() {
    const response = await this.request({
      url: "/token/csrf",
      method: "GET",
    });
    return response.data?.response?.csrftoken;
  }

  async fetchAccessAndRefresh() {
    const response = await this.request({
      url: "/step",
      method: "POST",
    });
    return {
      access: response?.data?.auth?.access,
      refresh: response?.data?.auth?.refresh,
    };
  }
  
  async updateAccessAndRefresh() {
    const { access, refresh } = await this.fetchAccessAndRefresh();

    access_token = access;
    refresh_token = refresh;
  };

  async refreshAccessToken() {
    if (!refresh_token) throw new Error("No refresh_token");
    
    const isExpired = this.checkRefreshTokenIsExpired();
    if (isExpired) return await this.fetchAccessAndRefresh();

    try {
      const response = await this.request({
        url: "/token/refresh",
        method: "POST",
        data: {
          refresh: refresh_token,
        },
      });

      const access = response?.data?.response?.access;
      const refresh = response?.data?.response?.refresh;

      access_token = access;
      refresh_token = refresh;

      return { access, refresh };
    } catch (e) {
      console.log(e);
    }
  }

  async request(config) {
    const data = { ...config?.data, ...this.commonRequestData };
    let requestNodeData = config?.data?.nodeData || {};
    
    if (this._useJuicyScore) {
      requestNodeData = { ...requestNodeData, ...this.juicyData };
      data.nodeData = requestNodeData || {};
    }
    
    return await this.instance.request({
      ...config,
      data,
    });
  }

  async requestWithTokens(config) {
    try {
      // Generate & set random deviceId
      this.createDeviceId();
      
      if (!csrf_token) {
        csrf_token = await this.fetchCsrfToken();
      }
      if (!access_token) {
        await this.updateAccessAndRefresh();
      }

      const isExpired = this.checkAccessTokenIsExpired();
      if (isExpired) await this.refreshAccessToken();

      if (this._useJuicyScore) await this.initJuicyParams();

      const requestConfig = {
        ...config,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "X-CSRFToken": btoa(csrf_token),
        },
      };
      
      const response = await this.request(requestConfig);
      
      if (response?.data?.auth) {
        const { access, refresh } = response?.data?.auth;
        
        access_token = access;
        refresh_token = refresh;
      }

      return await response;
    } catch (e) {
      if (e?.response?.status === 401) {
        await this.updateAccessAndRefresh();
        
        return await this.request(config);
      }
    }
  }
}

export default API;
