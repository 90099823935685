const setCurrentNode = (state, node) => {
  state.currentNode = node;
};

const setIsFetchingScreen = (state, isFetchingScreen) => {
  state.isFetchingScreen = isFetchingScreen;
};

const setIsNodeLoading = (state, isLoading) => {
  state.isNodeLoading = isLoading;
};

const setUseBackgroundSync = (state, isHidden) => {
  state.useBackgroundSync = isHidden;
};

const setTasksBeforeTransition = (state, tasks) => {
  state.tasksBeforeTransition = tasks;
};

// Clear non-permanent tasks only
const clearTasksBeforeTransition = (state) => {
  state.tasksBeforeTransition = state.tasksBeforeTransition.filter((item) => {
    return item?.isPermanent;
  });
};

// Clear non-permanent tasks only
const clearTasksAfterTransition = (state) => {
  state.tasksAfterTransition = state.tasksAfterTransition.filter((item) => {
    return item?.isPermanent;
  });
};

const setTasksAfterTransition = (state, tasks) => {
  state.tasksAfterTransition = tasks;
};

const setNeedToReRenderScreen = (state, status) => {
  state.needToReRenderScreen = status;
};

export default {
  setIsNodeLoading,
  setCurrentNode,
  setIsFetchingScreen,
  setUseBackgroundSync,
  setNeedToReRenderScreen,
  setTasksBeforeTransition,
  setTasksAfterTransition,
  clearTasksBeforeTransition,
  clearTasksAfterTransition,
};
