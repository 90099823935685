var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SectionComponent',{attrs:{"is-wider":""}},[_c('ContainerComponent',{attrs:{"is-wider":""}},[_c('SectionHeaderComponent',{attrs:{"is-wider":""}},[_c('HeadingComponent',{attrs:{"level":"h2"}},[_vm._v("Steps to apply for Credit Line")])],1),_c('VueSlickCarousel',_vm._b({ref:"slides-carousel",on:{"afterChange":_vm.afterChange}},'VueSlickCarousel',_vm.carouselSettings,false),_vm._l((_vm.slides),function(slide){return _c('div',{key:slide.id},[_c('SectionPanelComponent',{class:[
            _vm.$style['web-mb-slides-section__panel'],
            'selenium-main-block-steps-to-apply-slides',
          ]},[_c('div',{class:_vm.$style['web-mb-slides-section__row']},[_c('div',{class:[
                _vm.$style['web-mb-slides-section__col'],
                _vm.$style['web-mb-slides-section__img-wrap'],
              ]},[_c('ImgComponent',{attrs:{"src":slide.img,"img-style":{
                  width: 'auto',
                  height: '400px',
                  filter: 'drop-shadow(-8px 8px 24px #999)',
                },"loading":"lazy","alt":"Steps to Apply for Personal loan from VIVA Money Line of Credit App. Enjoy an easy & hassle-free process of taking interest free loans."}})],1),_c('div',{class:[
                _vm.$style['web-mb-slides-section__col'],
                _vm.$style['web-mb-slides-section__content-wrap'],
              ]},[_c('div',{class:_vm.$style['web-mb-slides-section__content']},[_c('HeadingComponent',{attrs:{"level":"h2","not-semantic":""}},[_vm._v(_vm._s(slide.title))]),_c('p',[_vm._v(_vm._s(slide.text))])],1),_c('div',{class:[
                  _vm.$style['web-mb-slides-section__nums'],
                  'selenium-main-block-steps-to-apply-slides-section-numbers',
                ]},_vm._l((_vm.slides.length),function(n){return _c('div',{key:n,class:[
                    {
                      [_vm.$style['web-mb-slides-section__current-slide']]:
                        n === slide.id,
                    },
                    _vm.$style['web-mb-slides-section__num'],
                  ],on:{"click":function($event){return _vm.showNext(n - 1)}}},[_vm._v(" "+_vm._s(n)+" ")])}),0)])])])],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }