import API, { baseConfig } from "@/services/index";

class StepsAPI extends API {
  constructor() {
    let baseURL = baseConfig.baseURL;
    const parsedUrl = new URL(window.location.href);
    const deprecatedBaseUrl = parsedUrl.searchParams.get("b");

    if (deprecatedBaseUrl) {
      baseURL = atob(deprecatedBaseUrl);
    }
    super({ baseURL, useJuicyScore: true });
  };
  
  async fetchStep(
    { destination, screenId, nodeData = {}, elementId = null, eventId = null },
    { nodeWithTokens = false }
  ) {
    const queryParams = new URLSearchParams({ nodeWithTokens });

    let data = {};

    if (destination && screenId) {
      data = {
        destination,
        screenId,
        nodeData,
      };
    }

    if (elementId && eventId) {
      data = { ...data, elementId, eventId };
    }

    return await this.requestWithTokens({
      url: "/step?" + queryParams,
      method: "POST",
      data,
    });
  }
}

const StepsApi = new StepsAPI({ useJuicyScore: true });
export default StepsApi;
