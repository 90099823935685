<template>
  <main :class="$style['web-mb-home-page']">
    <section :class="$style['web-mb-home-page__top-section']">
      <SectionHeaderComponent
        :class="$style['web-mb-home-page__top-section-header-wrap']"
      >
        <div :class="$style['web-mb-home-page__top-section-header']">
          <span>Live NOW in Maharashtra, </span>
          <span :class="$style['web-mb-home-page__top-section-header-part']"
          >Gujarat & Karnataka!</span
          >
          <span> More to Come!</span>
          <img
            :class="$style['web-mb-home-page__top-section-header-img']"
            src="../../../assets/icons/emoji-icon.svg"
            alt="emoji"
          />
        </div>
      </SectionHeaderComponent>
    </section>

    <!-- WELCOME -->
    <section :class="$style['web-mb-home-page__section']">
      <PagePanelComponent
        :class="[
          $style['web-mb-home-page__panel'],
          'selenium-main-block-info-apply',
        ]"
        role="img"
        aria-label="VIVA Money - Best Instant Personal Loan App for Online Personal Loans"
      >
        <ContainerComponent>
          <div :class="$style['web-mb-home-page__row']">
            <div :class="$style['web-mb-home-page__col']">
              <HeadingComponent level="h1"
                >India’s 1st Personal Line of Credit</HeadingComponent
              >
              <ul :class="$style['web-mb-home-page__list']">
                <li>
                  <HeadingComponent level="h1" reset-style
                    >Credit Limit up to ₹2,00,000</HeadingComponent
                  >
                </li>
                <li>
                  <HeadingComponent level="h1" reset-style
                    >0% interest for up to 51 days</HeadingComponent
                  >
                </li>
                <li>
                  <HeadingComponent level="h1" reset-style
                    >Direct bank transfer & revolving line of
                    credit</HeadingComponent
                  >
                </li>
              </ul>
              <div
                :class="[
                  $style['web-mb-home-page__row'],
                  $style['web-mb-home-page__download'],
                ]"
              >
                <div :class="$style['web-mb-home-page__col']">
                  <span :class="$style['web-mb-home-page__download-header']"
                    >Download mobile app</span
                  >
                  <div :class="$style['web-mb-home-page__download-wrap']">
                    <div :class="$style['web-mb-home-page__qr-code-wrap']">
                      <ImgComponent
                        :src="qrCodeImg"
                        :img-style="{
                          width: '128px',
                          height: '128px',
                          borderRadius: '0.5rem',
                        }"
                        alt="VIVA Money Download the App QR code"
                      />
                    </div>
                    <div
                      :class="$style['web-mb-home-page__download-links-wrap']"
                    >
                      <a
                        href="https://vivam.onelink.me/0rtl/zw1ld90q"
                        target="_blank"
                      >
                        <ImgComponent
                          :src="googlePlayImg"
                          :img-style="{ width: 'auto', height: '56px' }"
                          alt="VIVA Money Android App from Play Store"
                        />
                      </a>
                      <a
                        href="https://vivam.onelink.me/0rtl/zw1ld90q"
                        target="_blank"
                      >
                        <ImgComponent
                          :src="appStoreImg"
                          :img-style="{ width: 'auto', height: '56px' }"
                          alt="VIVA Money IOS App from Apple App Store"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              :class="[
                $style['web-mb-home-page__col'],
                $style['web-mb-home-page__img-wrap'],
              ]"
            ></div>
          </div>
          <!-- <div
            :class="[
              $style['web-mb-home-page__row'],
              $style['web-mb-home-page__row-btn-wrap'],
            ]"
          >
            <div :class="$style['web-mb-home-page__col']">
              <bpm-button fullwidth>APPLY</bpm-button>
            </div>
            <div :class="$style['web-mb-home-page__col']"></div>
            <div :class="$style['web-mb-home-page__col']"></div>
          </div> -->
        </ContainerComponent>
      </PagePanelComponent>
    </section>

    <AdvantagesSection :class="$style['web-mb-home-page__advantages']" />

    <!-- todo: overflow-x -->
    <WebMobBank v-if="showStep" />

    <SlidesSection />

    <VideoSection />

    <FeaturesSection />

    <AboutSection />

    <ReviewsSection />

    <BannerSection />

    <FaqSection />
  </main>
</template>

<script>
import PagePanelComponent from "@/components/ui/PagePanelComponent.vue";
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";

import HeadingComponent from "@/components/ui/elements/HeadingComponent.vue";

import AdvantagesSection from "@/components/site/sections/AdvantagesSection.vue";
import WebMobBank from "@/components/app/WebMobBank.vue";
import SlidesSection from "@/components/site/sections/SlidesSection.vue";
import AboutSection from "@/components/site/sections/AboutSection.vue";
import FeaturesSection from "@/components/site/sections/FeaturesSection.vue";
import VideoSection from "@/components/site/sections/VideoSection.vue";
import ReviewsSection from "@/components/site/sections/ReviewsSection.vue";
import BannerSection from "@/components/site/sections/BannerSection.vue";
import FaqSection from "@/components/site/sections/FaqSection.vue";
import SectionHeaderComponent from "@/components/ui/SectionHeaderComponent.vue";

import googlePlayImg from "@/assets/images/site/google-play-img.svg";
import appStoreImg from "@/assets/images/site/app-store-img.svg";
import qrCodeImg from "@/assets/images/site/mb-qr-code.png";

import img1 from "@/assets/images/home/back-img-1.webp";
import img2 from "@/assets/images/home/back-img-2.webp";
import img3 from "@/assets/images/home/back-img-3.webp";

export default {
  name: "HomePage",
  components: {
    PagePanelComponent,
    ContainerComponent,
    ImgComponent,
    HeadingComponent,
    AdvantagesSection,
    WebMobBank,
    SlidesSection,
    VideoSection,
    FeaturesSection,
    AboutSection,
    ReviewsSection,
    BannerSection,
    FaqSection,
    SectionHeaderComponent,
  },
  data: () => {
    return {
      googlePlayImg,
      appStoreImg,
      img1,
      img2,
      img3,
      qrCodeImg,
      isDesktop: false,
    };
  },
  computed: {
    showStep() {
      return process.env?.VUE_APP_SHOW_STEP || false;
    },
  },
  mounted() {
    console.log(process.env.VUE_APP_SHOW_STEP);

    if (
      !navigator.userAgent.match(/iPhone/i) &&
      !navigator.userAgent.match(/iPod/i) &&
      !navigator.userAgent.match(/iPad/i) &&
      !navigator.userAgent.match(/android/i)
    ) {
      this.isDesktop = true;
    }
  },
};
</script>

<style lang="scss" module>
.web-mb-home-page {
  &__section {
    margin-bottom: 1.5rem;
  }

  &__top-section {
    margin-bottom: 1.5rem;
    background-color: #f2e8fa;
    padding: 0.75rem;
    text-align: center;
  }

  &__top-section-header {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -2px;
    text-wrap: balance;
  }

  &__top-section-header-img {
    display: inline-block;
    width: 1em;
    height: auto;
    margin-bottom: -4px;
    margin-left: 6px;
  }

  &__top-section-header-part {
    font-weight: 600;
  }

  &__top-section-header-wrap {
    padding: 0;
  }

  &__panel {
    background-image: url(../../../assets/images/home/back-img-3.webp);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 8.25rem;
  }

  &__advantages {
    margin-top: -6.25rem;
  }

  &__row {
    display: flex;
  }

  &__col {
    flex: 1;
  }

  &__list {
    list-style-position: inside;
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 2rem;
    padding-bottom: 2rem;
    // padding-right: 4rem;
  }

  &__list li {
    position: relative;
    padding-bottom: 1rem;
    padding-left: 1rem;
    list-style: none;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.6rem;
      width: 0.5rem;
      height: 0.5rem;
      background-image: url(../../../assets/icons/list-item-icon.svg);
    }
  }

  &__img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
  }

  &__download {
    padding-bottom: 30%;

    &-header {
      font-weight: 500;
    }
  }

  &__download &__img-wrap {
    flex: 1;
  }

  &__download-wrap {
    padding-top: 1rem;
  }

  &__download-wrap,
  &__download-links-wrap {
    display: flex;
    gap: 1rem;
  }

  &__download-links-wrap {
    flex-direction: column;
  }

  &__qr-code-wrap {
    display: none;
  }

  &__img-wrap {
    flex: 0;
  }

  &__row-btn-wrap &__col:nth-child(2),
  &__row-btn-wrap &__col:nth-child(3) {
    flex: 0;
  }

  @media (min-width: $min-xs) {
    &__panel {
      background-image: url(../../../assets/images/home/back-img-2.webp);
      background-position: 75%;
    }
  }

  @media (min-width: $min-md) {
    &__panel {
      // background-image: url(../../../assets/images/home/back-img-2.webp);
      // padding-bottom: 8.25rem;
      background-position: bottom;
    }

    &__list li {
      padding-bottom: 1.5rem;
    }

    &__download {
      padding-bottom: 0%;
      // padding-top: 10%;
    }

    &__row-btn-wrap &__col:nth-child(2) {
      flex: 1;
    }

    &__top-section-header-wrap {
      padding: 0;
    }

    &__top-section-header {
      font-size: 1.75rem;
    }

    &__top-section-header-img {
      margin-bottom: -6px;
    }

    &__qr-code-wrap {
      display: block;
    }
  }

  @media (min-width: $min-lg) {
    &__panel {
      background-image: url(../../../assets/images/home/back-img-1.webp);
      background-position: initial;
    }

    &__download &__img-wrap {
      flex: 0;
    }

    &__img-wrap {
      flex: 1;
    }

    &__download {
      padding-top: 0;
    }

    &__row-btn-wrap &__col:nth-child(3) {
      flex: 1;
    }

    &__top-section-header-wrap {
      padding: 0;
    }
  }
}
</style>
