var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{ref:"header",class:[
    _vm.$style['web-mb-header'],
    { [_vm.$style['web-mb-header--is-sticky']]: _vm.isSticky },
    { [_vm.$style['web-mb-header--is-open']]: _vm.isOpen },
  ]},[_c('ContainerComponent',{class:_vm.$style['web-mb-header__container'],style:({ maxWidth: 'none' })},[_c('div',{class:_vm.$style['web-mb-header__panel']},[_c('ContainerComponent',[_c('div',{class:_vm.$style['web-mb-header__row']},[_c('div',{class:_vm.$style['web-mb-header__column']},[_c('a',{attrs:{"href":"/"}},[_c('ImgComponent',{attrs:{"src":_vm.logoImg,"alt":"NOVA Logo","img-style":{ width: '85px', height: 'auto' }}})],1),_c('nav',{class:_vm.$style['web-mb-header__nav']},[_vm._l((_vm.currentRoutes),function(route){return [(route.nav.header.show && !route.submenu)?_c('div',{key:route.name,class:[
                    {
                      [_vm.$style['web-mb-header__active-page']]:
                        route.path === _vm.$route.path,
                    },
                  ]},[_c('a',{class:route.nav.header.class,attrs:{"href":route.path}},[_vm._v(_vm._s(route.title))])]):_vm._e()]}),(!_vm.isEmployees)?_c('DropdownMenu'):_vm._e(),_c('div',{class:_vm.$style['web-mb-header__double-divider']},[_vm._v(" "+_vm._s(_vm.isEmployees ? "|" : "||")+" ")]),_c('a',{class:_vm.$style['web-mb-header__source-wrap'],attrs:{"href":_vm.isEmployees ? '/' : '/employees',"target":"_blank"}},[_vm._v(_vm._s(_vm.isEmployees ? "For Employer" : "For Employees")+" ")])],2)]),(_vm.isEmployees)?_c('div',{class:[
              _vm.$style['web-mb-header__column'],
              _vm.$style['web-mb-header__actions'],
            ]},[_c('bpm-button',{class:_vm.$style['web-mb-header__button'],attrs:{"background-color":"#043AD9"},on:{"click":_vm.openModal}},[_vm._v(" Get App ")])],1):_c('div',{class:[
              _vm.$style['web-mb-header__column'],
              _vm.$style['web-mb-header__actions'],
              _vm.$style['web-mb-header__btns-wrap'],
            ]},[_c('ButtonLinkComponent',{attrs:{"href":"https://dev.pk.2pp.dev/back-office/login","bg-color":"#DEF0FD","color":"#043AD9"}},[_vm._v("Log in")]),_c('ButtonLinkComponent',{attrs:{"href":"#book-a-demo","bg-color":"#043AD9","color":"#ffffff"}},[_vm._v("Book a Demo")])],1),_c('div',{class:[
              _vm.$style['web-mb-header__mb-nav-icon'],
              { [_vm.$style['web-mb-header__mb-nav-icon--is-open']]: _vm.isOpen },
            ],on:{"click":_vm.toggleMenu}})]),_c('nav',{class:[
            _vm.$style['web-mb-header__row'],
            _vm.$style['web-mb-header__mb-nav'],
            { [_vm.$style['web-mb-header__mb-nav--is-open']]: _vm.isOpen },
          ]},[_vm._l((_vm.currentRoutes),function(route){return [(route.nav.header.show && !route.submenu)?_c('div',{key:route.name,class:[
                {
                  [_vm.$style['web-mb-header__active-page']]:
                    route.path === _vm.$route.path,
                },
              ],on:{"click":_vm.handleClick}},[_c('a',{attrs:{"href":route.path}},[_vm._v(_vm._s(route.title))])]):_vm._e()]}),(!_vm.isEmployees)?_c('DropdownMenu'):_vm._e(),_c('a',{class:_vm.$style['web-mb-header__source-wrap'],attrs:{"href":_vm.isEmployees ? '/' : '/employees',"target":"_blank"}},[_vm._v(_vm._s(_vm.isEmployees ? "For Employer" : "For Employees")+" ")]),(_vm.isEmployees)?_c('bpm-button',{attrs:{"fullwidth":"","background-color":"#043AD9"},on:{"click":_vm.openModal}},[_vm._v("Get App")]):_c('div',{class:_vm.$style['web-mb-header__mb-nav-btn-wrap'],on:{"click":_vm.handleClick}},[_c('ButtonLinkComponent',{attrs:{"href":"https://dev.pk.2pp.dev/back-office/login","bg-color":"#DEF0FD","color":"#043AD9"}},[_vm._v("Log in")]),_c('ButtonLinkComponent',{attrs:{"href":"#book-a-demo","bg-color":"#043AD9","color":"#ffffff"}},[_vm._v("Book a Demo")])],1)],2)])],1)]),_c('div',{class:[
      _vm.$style['web-mb-header__background'],
      { [_vm.$style['web-mb-header__background_visible']]: _vm.isOpen },
    ]}),_c('GetAppModalComponent',{attrs:{"is-open":_vm.isOpenModal},on:{"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }