export const enableNodeLoaderTask = {
  actionType: "setIsNodeLoading",
  actionPayload: true,
  actionOptions: {},
  isAsync: false,
  isPermanent: true,
};

export const disableNodeLoaderTask = {
  actionType: "setIsNodeLoading",
  actionPayload: false,
  actionOptions: {},
  isAsync: false,
  isPermanent: true,
};

export const enableScreenFetchingTask = {
  actionType: "setIsFetchingScreen",
  actionPayload: true,
  actionOptions: {},
  isAsync: false,
  isPermanent: true,
};

export const disableScreenFetchingTask = {
  actionType: "setIsFetchingScreen",
  actionPayload: false,
  actionOptions: {},
  isAsync: false,
  isPermanent: true,
};

export const check4DigsOTPStatus = {
  actionType: "node/screen/otp4Digs/checkIsAllowedScreenToStart4DigsOTPCheck",
  actionPayload: null,
  actionOptions: { root: true },
  isAsync: true,
  isPermanent: true,
};

export const checkNeedToReRenderScreenTask = {
  actionType: "node/checkNeedToReRenderScreen",
  actionPayload: null,
  actionOptions: { root: true },
  isAsync: true,
  isPermanent: true,
};

export const checkScreenAutoUpdate = {
  actionType: "node/screen/processingScreenUpdate",
  actionPayload: null,
  actionOptions: { root: true },
  isAsync: true,
  isPermanent: true,
};

export const check6DigsOTPStatus = {
  actionType: "node/screen/otp6Digs/checkIsAllowedScreenToStart6DigsOTPCheck",
  actionPayload: null,
  actionOptions: { root: true },
  isAsync: true,
  isPermanent: true,
};
