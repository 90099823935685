const formatLmsOtpTime = ({ unit, cnt }) => {
  const getTimeMultiplier = (unitString) => {
    if (unitString === "minute" && cnt < 60) return 1000 * 60;
    if (unitString === "second") return 1000;
    if (unitString === "hours") return 1000000 * 60 * 60;
    
    return 1000;
  };
  
  const timeMs = cnt * getTimeMultiplier(unit);
  
  return new Date(timeMs).toISOString().slice(11, 19);
};

export default formatLmsOtpTime;
