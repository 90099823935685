<template>
  <div :class="[$style['dropdown']]">
    <div
      :class="[
        {
          [$style['dropdown__button_active']]: submenuPaths.includes(
            $route.path
          ),
          [$style['dropdown__button_colored']]: colored,
        },
        $style['dropdown__button'],
      ]"
      @click="toggleDropdown"
    >
      Company
      <img-component
        :class="[$style['dropdown__arrow']]"
        :src="isOpen ? up_arrow : down_arrow"
      />
    </div>
    <ul
      v-if="isOpen"
      :class="[
        isFooter ? [$style['dropdown__menu_footer']] : [$style['dropdown__menu_header']],
        $style['dropdown__menu'],
      ]">
      <li v-for="menuItem in menuItems" :key="menuItem.path">
        <a :href="menuItem.path" :class="$style['dropdown__link']">{{
          menuItem.title
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import routes from "@/router/routes.pakistan";
import ImgComponent from "@/components/ui/ImgComponent.vue";
import down_arrow from "@/assets/icons/down-arrow.svg";
import up_arrow from "@/assets/icons/arrow-up.svg";
export default {
  name: "DropdownMenu",
  components: { ImgComponent },
  props: {
    colored: {
      type: Boolean,
      default: false,
    },
    isFooter: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: false,
      routes,
      up_arrow,
      down_arrow,
    };
  },
  computed: {
    menuItems() {
      return this.routes.filter((route) => route.submenu);
    },
    submenuPaths() {
      return this.menuItems.reduce((acc, val) => {
        acc.push(val.path);
        return acc;
      }, []);
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.dropdown {
  z-index: 1000;
  position: relative;
  display: inline-block;
  &__arrow {
    display: none;
  }
  &__button {
    cursor: pointer;
    color: #0e1a22;
    text-decoration: none;
    transition: color 0.15s ease-in-out;
    &_active,
    &:hover {
      color: #043ad9;
    }

    &_colored {
      color: #043ad9;
      font-weight: 600;
    }
  }
  &__menu {
    border-radius: 8px;
    position: absolute;
    left: -10px;
    background: #043ad9;
    list-style: none;
    padding: 5px;
    margin: 0;
    // border: 1px solid #ddd;
    z-index: 1;
    li {
      // width: 100px;
      padding: 10px;
    }
    min-width: max-content;
    &_footer {
      bottom: 120%;
    }
    &_header {
      top: 120%;
    }
  }
  &__link {
    color: #fff !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-align: left;
    &:hover {
      color: #000 !important;
    }
  }
  @media (max-width: 767px) {
    &__button {
      display: flex;
      justify-content: center;
      gap: 13px;
      align-items: center;
    }
    &__arrow {
      display: block;
    }
    &__menu {
      position: relative;
      background: #fff;
      border: 0;
      text-align: center;
      color: #000;
      padding: 0;
      li {
        width: 100%;
        padding: 0;
        margin-top: 24px;
        &:first-child {
          margin-top: 32px;
        }
      }
    }
    &__link {
      color: #000 !important;
    }
  }
}
</style>