// Fixme: Временное решение, загружаем главную страницу со всем бандлом, чтобы убрать смещение макета
// При этом остальные страницы загружаем лениво, чтобы не раздувать бандл
// Чтобы убрать смещение макета, нужно будет сделать лоадер в App.vue
// Смещение макета вызвано тем, что пока загружается содержимое страницы, показывается футер, который потом уезжает вниз и вызывает смещение
import HomePage from "@/components/site/pages/HomePage.vue";

const routes = [
  {
    name: "home",
    title: "Home",
    path: "/",
    meta: {
      title: "VIVA Money - Personal Loan App with Credit Line Up to ₹2 Lac",
      description:
        "VIVA Money is India's 1st Line of Credit App that deals in Instant Personal Loans with credit line up to ₹2 Lac at 0% Interest i.e. interest-free loan up to 51 days.",
      og: {
        title: "VIVA Money - Personal Loan App with Credit Line Up to ₹2 Lac",
        descr:
          "VIVA Money is India's 1st Line of Credit App that deals in Instant Personal Loans with credit line up to ₹2 Lac at 0% Interest i.e. interest-free loan up to 51 days.",
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: true,
        class: "selenium-footer-pages-home",
      },
    },
    component: HomePage,
  },
  {
    name: "products",
    title: "Products",
    path: "/personal-line-of-credit",
    meta: {
      title: "Apply Instant Personal Loan Online | Viva Money Quick Loan App",
      description:
        "Get instant loans for up to ₹2 lacs at 0% interest for up to 51 days. Viva Money offers instant personal loans at lower interest rates. Apply for a quick loan today!",
      og: {
        title: "Apply Instant Personal Loan Online | Viva Money Quick Loan App",
        descr:
          "Get instant loans for up to ₹2 lacs at 0% interest for up to 51 days. Viva Money offers instant personal loans at lower interest rates. Apply for a quick loan today!",
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: true,
        class: "selenium-header-menu-list-href-products",
      },
      footer: {
        show: true,
        class: "selenium-footer-pages-products",
      },
    },
    component: () => import("@/components/site/pages/ProductsPage.vue"),
  },
  {
    name: "about",
    title: "About Us",
    path: "/about-us",
    meta: {
      title: "About Us - VIVA Money Line of Credit App | Personal Loan App",
      description:
        "VIVA Money is India's 1st Line of Credit App that deals in Instant Personal Loans with a credit line aiming to become India's best instant personal loan app.",
      og: {
        title: "About Us - VIVA Money Line of Credit App | Personal Loan App",
        descr:
          "VIVA Money is India's 1st Line of Credit App that deals in Instant Personal Loans with a credit line aiming to become India's best instant personal loan app.",
        // team img
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: true,
        class: "selenium-header-menu-list-href-about",
      },
      footer: {
        show: true,
        class: "",
      },
    },
    component: () => import("@/components/site/pages/AboutPage.vue"),
  },
  {
    name: "contact",
    title: "Contact Us",
    path: "/reach-us",
    meta: {
      title: "Contact Us - VIVA Money Customer Support Details | Loan App",
      description:
        "For any queries regarding the VIVA Money app, please contact us at chat@vivamoney.in. VIVA Money's customer care number will be available soon.",
      og: {
        title: "Contact Us - VIVA Money Customer Support Details | Loan App",
        descr:
          "For any queries regarding the VIVA Money app, please contact us at chat@vivamoney.in. VIVA Money's customer care number will be available soon.",
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: true,
        class: "selenium-header-menu-list-href-contacts",
      },
      footer: {
        show: true,
        class: "",
      },
    },
    component: () => import("@/components/site/pages/ReachPage.vue"),
  },
  {
    name: "blog",
    title: "Blog",
    path: "/blog",
    meta: {
      title:
        "Finance Blogs India | Discover Personal Finance Articles & News - VIVA Blogs",
      description:
        "Stay up-to-date with the latest fintech industry news and blogs. This is your complete guide to personal finance, investments, money management, and making financial decisions.",
      og: {
        title:
          "Finance Blogs India | Discover Personal Finance Articles & News - VIVA Blogs",
        descr:
          "Stay up-to-date with the latest fintech industry news and blogs. This is your complete guide to personal finance, investments, money management, and making financial decisions.",
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: true,
        class: "selenium-header-menu-list-href-blog",
      },
      footer: {
        show: true,
        class: "selenium-footer-pages-blog",
      },
    },
    component: () => import("@/components/site/pages/BlogPage.vue"),
  },
  {
    name: "article",
    title: "Article",
    path: "/blog/:id",
    meta: {
      title: "",
      description: "",
      og: {
        title: "",
        descr: "",
        // article's img
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    component: () => import("@/components/site/pages/ArticlePage.vue"),
  },
  {
    name: "terms",
    title: "Terms And Conditions",
    path: "/terms-and-conditions",
    meta: {
      title: "Terms and Conditions - VIVA Money Instant Personal Loan App",
      description:
        "Navigate through VIVA Money's terms and conditions to ensure a seamless user experience. Stay informed about your rights and responsibilities while engaging with India's 1st Line of Credit App",
      og: {
        title: "Terms and Conditions - VIVA Money Instant Personal Loan App",
        descr:
          "Navigate through VIVA Money's terms and conditions to ensure a seamless user experience. Stay informed about your rights and responsibilities while engaging with India's 1st Line of Credit App",
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "selenium-footer-docs-terms-and-conditions",
      },
    },
    component: () =>
      import("@/components/site/pages/TermsAndConditionsPage.vue"),
  },
  {
    name: "privacy",
    title: "Privacy Policy",
    path: "/privacy-policy",
    meta: {
      title: "Privacy Policy: VIVA Money",
      description:
        "Discover how VIVA Money safeguards and protects your privacy. Our comprehensive Privacy Policy showcases the measures that we take to protect your information.",
      og: {
        title: "Privacy Policy: VIVA Money",
        descr:
          "Discover how VIVA Money safeguards and protects your privacy. Our comprehensive Privacy Policy showcases the measures that we take to protect your information.",
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "selenium-footer-docs-privacy-policy",
      },
    },
    component: () => import("@/components/site/pages/PrivacyPolicyPage.vue"),
  },
  {
    name: "fair",
    title: "Fair Practices Code",
    path: "/fair-practices-code",
    meta: {
      title: "Fair Practices Code: VIVA Money Loan App",
      description:
        "Explore VIVA Money’s Fair Practices Code page to understand how we prioritize your interests. Discover our commitment of providing an equitable experience for all our users.",
      og: {
        title: "Fair Practices Code: VIVA Money Loan App",
        descr:
          "Explore VIVA Money’s Fair Practices Code page to understand how we prioritize your interests. Discover our commitment of providing an equitable experience for all our users.",
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "selenium-footer-docs-fair-practices-code",
      },
    },
    component: () =>
      import("@/components/site/pages/FairPracticesCodePage.vue"),
  },
  {
    path: "/qr-code",
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    component: () => import("@/components/site/pages/QrCode.vue"),
  },
  {
    path: "/web-mob-bank-fake-route",
    meta: {
      title: "",
      description: "",
      og: {
        title: "",
        descr: "",
        img: "/img/viva-money-app.jpg",
      },
    },
    nav: {
      header: {
        show: true,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    component: () => import("@/components/app/WebMobBank.vue"),
  },
];

export default routes;
