<template>
  <SectionComponent is-wider>
    <ContainerComponent is-wider>
      <!-- todo: fix problem with copies -->
      <VueSlickCarousel v-bind="carouselSettings">
        <div v-for="advantage in advantages" :key="advantage.id">
          <div :class="$style['web-mb-advantages-section__card']">
            <div :class="$style['web-mb-advantages-section__card-header']">
              <ImgComponent
                :src="advantage.img"
                :img-style="{
                  width: '40px',
                  height: 'auto',
                  paddingTop: '8px',
                }"
                :alt="advantage.alt"
              />
              <HeadingComponent
                level="h3"
                v-html="advantage.title"
              ></HeadingComponent>
            </div>
            <div
              :class="$style['web-mb-advantages-section__card-text']"
              v-html="advantage.text"
            ></div>
          </div>
        </div>
      </VueSlickCarousel>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import SectionComponent from "@/components/ui/SectionComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";

import HeadingComponent from "@/components/ui/elements/HeadingComponent.vue";

import VueSlickCarousel from "vue-slick-carousel";

import img1 from "@/assets/images/advantages/advantage-1-img.svg";
import img2 from "@/assets/images/advantages/advantage-2-img.svg";
import img3 from "@/assets/images/advantages/advantage-3-img.svg";
import img4 from "@/assets/images/advantages/advantage-4-img.svg";

export default {
  name: "AdvantagesSection",
  components: {
    ContainerComponent,
    SectionComponent,
    ImgComponent,
    HeadingComponent,
    VueSlickCarousel,
  },
  data: () => {
    return {
      // json
      advantages: [
        {
          id: 1,
          title: "0% Interest<br> rate",
          text: "If repaid within the Grace period up to 51 days",
          img: img1,
          alt: "0% Interest Rate",
        },
        {
          id: 2,
          title: "Credit line with EMI",
          text: "Withdraw & repay in easy EMI of 5-20 months",
          img: img2,
          alt: "Flexible EMI Options",
        },
        {
          id: 3,
          title: "Lightning-fast approvals",
          text: "Get approval in less than 15 minutes",
          img: img3,
          alt: "Instant Personal Loan",
        },
        {
          id: 4,
          title: "Zero<br> Paper",
          text: "100% Digital Proccess",
          img: img4,
          alt: "Online Personal Loan",
        },
      ],
      carouselSettings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
              centerMode: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              centerMode: true,
              // 'initialSlide': 2
            },
          },
          // {
          //   'breakpoint': 480,
          //   'settings': {
          //     'slidesToShow': 1,
          //     'slidesToScroll': 1
          //   }
          // }
        ],
      },
    };
  },
};
</script>

<style lang="scss" module>
.web-mb-advantages-section {
  &__card {
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem 1.5rem;
    margin: 0 0.5rem;
    border: 1px solid #f5f7fb;
    flex: 1;

    &-header {
      display: flex;
      gap: 1.5rem;
      align-items: center;
      padding-bottom: 1rem;
    }

    &-text {
      font-size: 0.875em;
    }
  }

  @media (min-width: $min-md) {
    &__card {
      padding: 2rem 1.5rem;
    }
  }
}
</style>
