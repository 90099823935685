import axios from "axios";

import promiseWithTimeout from "@/utils/promiseWithTimeout";
import getCookieByKey from "@/utils/getCookiesByKey";

const userDataURL = process.env?.VUE_APP_USER_DATA_CHECK_URL;
const serviceUrl = process.env?.VUE_APP_SERVICE_V1_URL;

const antifraudChannel = "SITE";
const backendHost = new URL(serviceUrl)?.host;

let userCache = null;

const getUserData = async () => {
  if (userCache) return userCache;

  const userDataResponse = await axios.get(userDataURL, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

  userCache = userDataResponse.data;
  return userDataResponse.data;
};

const getCurrentTimeZone = () => {
  // 100% copium
  const offset = new Date().getTimezoneOffset();
  let hours;

  if (offset < 0) hours = "+" + offset/-60;
  else hours = "" + offset/60;

  return "GMT" + hours;
};

const getJuicyScoreParams = async () => {
  const juicyVersion = window?.juicyScoreApi.getVersion();
  const sessionId = await promiseWithTimeout(
    3000,
    window?.juicyScoreApi.getSessionId(),
  );
  
  const { ip: userIP, user_agent: userAgent } = await getUserData();
  const afUserId = getCookieByKey("afUserId");
  const timeZone = getCurrentTimeZone();
  
  //   juicyVersion -> antifraudVersion
  // userAgent -> antifraudUserAgent
  // userIP -> antifraudIP
  // sessionId -> antifraudSessionId

  return {
    antifraudVersion: juicyVersion || "",
    antifraudSessionId: sessionId || "",
    antifraudIP: userIP || "",
    antifraudUserAgent: userAgent || "",
    antifraudChannel,
    afUserId,
    timeZone,
    backendHost,
  };
};

export default getJuicyScoreParams;
