<template>
  <div
    id="app"
    :class="[
      $style['web-mb-app'],
      { [$style['web-mb-app--pakistan']]: isPakistan },
    ]"
  >
    <template v-if="!showOnlyRouterView">
      <HeaderComponentPakistan v-if="isPakistan" />
      <HeaderComponent v-else />
      <router-view></router-view>
      <FooterComponentPakistan v-if="isPakistan" />
      <FooterComponent v-else />
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import HeaderComponent from "@/components/site/HeaderComponent.vue";
import FooterComponent from "@/components/site/FooterComponent.vue";
import HeaderComponentPakistan from "@/components/pakistan/site/HeaderComponentPakistan.vue";
import FooterComponentPakistan from "@/components/pakistan/site/FooterComponentPakistan.vue";

import initLiveChatWidget from "@/libs/liveChat";

import data from "@/data/blog.json";
import employeesData from "@/data/pakistan/employees/blog.json";

import employersFintechData from "@/data/pakistan/employers/fintechBlog.json";
import employersResorcesData from "@/data/pakistan/employers/resorcesBlog.json";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    HeaderComponentPakistan,
    FooterComponentPakistan,
  },
  data: () => {
    return {
      // articles: data?.articles,
      // TEMPORARY SOLUTION FOR PAKISTAN HEADER AND FOOTER
      isPakistan: process.env.VUE_APP_PROJECT_REGION === "PAKISTAN_DEV",
      showOnlyRouterView: false,
    };
  },
  computed: {
    showChat() {
      return process.env?.VUE_APP_SHOW_CHAT || false;
    },
    articles() {
      return this.isPakistan
        ? this.$route.name === "employersArticle"
          ? (employersFintechData?.articles).concat(
              employersResorcesData?.articles
            )
          : employeesData?.articles
        : data?.articles;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // Tmp fix to hide header & footer (for iframe showing)
        this.showOnlyRouterView = to?.path === "/web-mob-bank-fake-route";
        
        // todo
        document.title =
          to.meta.title ||
          (this.isPakistan
            ? "NOVA | Boost your employee finacial wellbeing"
            : "VIVA Money - Personal Loan App with Credit Line Up to ₹2 Lac");
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            to.meta.description ||
              (this.isPakistan
                ? "Welcome to Nova, Pakistan's premier Earned Wage Access partner for businesses. Streamline payroll processing, optimise productivity and boost retention with ease."
                : "VIVA Money is India's 1st Line of Credit App that deals in Instant Personal Loans with credit line up to ₹2 Lac at 0% Interest i.e. interest-free loan up to 51 days.")
          );

        // todo
        if (
          to.name === "article" ||
          to.name === "employeesArticle" ||
          to.name === "employersArticle"
        ) {
          const article = this.articles.filter(
            (article) =>
              (this.isPakistan ? "" : "/blog/") + article.url === to.path
          )?.[0];

          document.title = article?.meta?.title || to.meta.title;
          document
            .querySelector('meta[name="description"]')
            .setAttribute(
              "content",
              article?.meta?.description || to.meta.description
            );
        }
      },
    },
  },
  mounted() {
    if (this.showChat && window.location.pathname !== "/web-mob-bank-fake-route") {
      initLiveChatWidget();
    }
  },
};
</script>

<style lang="scss" module>
.web-mb-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  // overflow-x: hidden;

  &--pakistan {
    background-color: #ffffff;
  }

  // tablet
  @media (min-width: $min-md) {
    font-size: 1.125rem;
  }

  // desktop
  @media (min-width: $min-lg) {
  }
}
</style>
