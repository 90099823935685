var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SectionComponent',[_c('ContainerComponent',[_c('SectionHeaderComponent',[_c('HeadingComponent',{attrs:{"level":"h2","not-semantic":""}},[_vm._v("About us")])],1),_c('div',{class:_vm.$style['web-mb-about-section__row']},[_c('div',{class:[
          _vm.$style['web-mb-about-section__col'],
          _vm.$style['web-mb-about-section__content'],
        ]},[_c('p',[_vm._v(" Viva Money is a digital financial lending platform offering India’s 1st Line of Credit. By partnering up with Fincfriends Private Limited registered with RBI, VIVA provides an affordable & easy way of taking a revolving credit line. ")]),_c('p',[_vm._v(" Viva Money stands as a pioneering member within the Viva family, as it extends its presence to various countries including Mexico and Italy. ")]),_c('a',{class:[
            _vm.$style['web-mb-about-section__link-btn'],
            'selenium-main-block-about-us-button-learn-more',
          ],attrs:{"href":"/about-us"}},[_vm._v("Learn more")])]),_c('div',{class:[
          _vm.$style['web-mb-about-section__col'],
          _vm.$style['web-mb-about-section__img-wrap'],
        ]},[_c('ImgComponent',{class:_vm.$style['web-mb-about-section__img-sm'],attrs:{"src":_vm.aboutImgMd,"alt":"VIVA Money team"}}),_c('ImgComponent',{class:_vm.$style['web-mb-about-section__img-md'],attrs:{"src":_vm.aboutImgMd,"alt":"VIVA Money team"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }