var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.$style['dropdown']]},[_c('div',{class:[
      {
        [_vm.$style['dropdown__button_active']]: _vm.submenuPaths.includes(
          _vm.$route.path
        ),
        [_vm.$style['dropdown__button_colored']]: _vm.colored,
      },
      _vm.$style['dropdown__button'],
    ],on:{"click":_vm.toggleDropdown}},[_vm._v(" Company "),_c('img-component',{class:[_vm.$style['dropdown__arrow']],attrs:{"src":_vm.isOpen ? _vm.up_arrow : _vm.down_arrow}})],1),(_vm.isOpen)?_c('ul',{class:[
      _vm.isFooter ? [_vm.$style['dropdown__menu_footer']] : [_vm.$style['dropdown__menu_header']],
      _vm.$style['dropdown__menu'],
    ]},_vm._l((_vm.menuItems),function(menuItem){return _c('li',{key:menuItem.path},[_c('a',{class:_vm.$style['dropdown__link'],attrs:{"href":menuItem.path}},[_vm._v(_vm._s(menuItem.title))])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }