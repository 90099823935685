import Vue from "vue";
import VueRouter from "vue-router";

import mapRoutes from "@/router/mapRoutes";
import getCurrentInstanceConfig from "@/utils/getCurrentInstanceConfig";

const { currentInstanceLocalName } = getCurrentInstanceConfig();
const routes = mapRoutes[currentInstanceLocalName];

Vue.use(VueRouter);

// Deprecated
// Обертка над асинхронной загрузкой роутов
// const lazyLoadRoute = AsyncView => {
//   const AsyncHandler = () => ({
//     component: AsyncView,
//     loading: PageLoaderComponent,
//   });
//
//   return Promise.resolve({
//     functional: true,
//     render(h, { data, children }) {
//       // Transparently pass any props or children
//       // to the view component.
//       return h(AsyncHandler, data, children);
//     }
//   });
// };

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

export default router;
