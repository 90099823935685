<template>
  <component
    :is="!notSemantic ? level : 'div'"
    :class="[
      $style['web-mb-heading-' + level],
      { [$style['web-mb-heading--reset']]: resetStyle },
      { [$style['web-mb-heading--hide']]: hide },
    ]"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "HeadingComponent",
  props: {
    level: {
      type: String,
      default: "h1", // h1-h6
    },
    notSemantic: {
      type: Boolean,
      default: false,
    },
    resetStyle: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.web-mb-heading {
  &-h1,
  &-h2,
  &-h3,
  &-h4,
  &-h5,
  &-h6 {
    font-weight: 600;
  }

  &-h1,
  &-h2 {
    font-size: 1.375rem;
  }

  &-h3 {
    font-size: 1.25rem;
  }

  // todo: h4-h6 sizes

  &--reset {
    font-weight: inherit;
    font-size: inherit;
  }

  &--hide {
    display: none;
  }

  @media (min-width: $min-md) {
    &-h1:not(&--reset),
    &-h2:not(&--reset) {
      font-size: 1.75rem;
    }
  }
}
</style>
