var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{class:_vm.$style['web-mb-home-page']},[_c('section',{class:_vm.$style['web-mb-home-page__top-section']},[_c('SectionHeaderComponent',{class:_vm.$style['web-mb-home-page__top-section-header-wrap']},[_c('div',{class:_vm.$style['web-mb-home-page__top-section-header']},[_c('span',[_vm._v("Live NOW in Maharashtra, ")]),_c('span',{class:_vm.$style['web-mb-home-page__top-section-header-part']},[_vm._v("Gujarat & Karnataka!")]),_c('span',[_vm._v(" More to Come!")]),_c('img',{class:_vm.$style['web-mb-home-page__top-section-header-img'],attrs:{"src":require("../../../assets/icons/emoji-icon.svg"),"alt":"emoji"}})])])],1),_c('section',{class:_vm.$style['web-mb-home-page__section']},[_c('PagePanelComponent',{class:[
        _vm.$style['web-mb-home-page__panel'],
        'selenium-main-block-info-apply',
      ],attrs:{"role":"img","aria-label":"VIVA Money - Best Instant Personal Loan App for Online Personal Loans"}},[_c('ContainerComponent',[_c('div',{class:_vm.$style['web-mb-home-page__row']},[_c('div',{class:_vm.$style['web-mb-home-page__col']},[_c('HeadingComponent',{attrs:{"level":"h1"}},[_vm._v("India’s 1st Personal Line of Credit")]),_c('ul',{class:_vm.$style['web-mb-home-page__list']},[_c('li',[_c('HeadingComponent',{attrs:{"level":"h1","reset-style":""}},[_vm._v("Credit Limit up to ₹2,00,000")])],1),_c('li',[_c('HeadingComponent',{attrs:{"level":"h1","reset-style":""}},[_vm._v("0% interest for up to 51 days")])],1),_c('li',[_c('HeadingComponent',{attrs:{"level":"h1","reset-style":""}},[_vm._v("Direct bank transfer & revolving line of credit")])],1)]),_c('div',{class:[
                _vm.$style['web-mb-home-page__row'],
                _vm.$style['web-mb-home-page__download'],
              ]},[_c('div',{class:_vm.$style['web-mb-home-page__col']},[_c('span',{class:_vm.$style['web-mb-home-page__download-header']},[_vm._v("Download mobile app")]),_c('div',{class:_vm.$style['web-mb-home-page__download-wrap']},[_c('div',{class:_vm.$style['web-mb-home-page__qr-code-wrap']},[_c('ImgComponent',{attrs:{"src":_vm.qrCodeImg,"img-style":{
                        width: '128px',
                        height: '128px',
                        borderRadius: '0.5rem',
                      },"alt":"VIVA Money Download the App QR code"}})],1),_c('div',{class:_vm.$style['web-mb-home-page__download-links-wrap']},[_c('a',{attrs:{"href":"https://vivam.onelink.me/0rtl/zw1ld90q","target":"_blank"}},[_c('ImgComponent',{attrs:{"src":_vm.googlePlayImg,"img-style":{ width: 'auto', height: '56px' },"alt":"VIVA Money Android App from Play Store"}})],1),_c('a',{attrs:{"href":"https://vivam.onelink.me/0rtl/zw1ld90q","target":"_blank"}},[_c('ImgComponent',{attrs:{"src":_vm.appStoreImg,"img-style":{ width: 'auto', height: '56px' },"alt":"VIVA Money IOS App from Apple App Store"}})],1)])])])])],1),_c('div',{class:[
              _vm.$style['web-mb-home-page__col'],
              _vm.$style['web-mb-home-page__img-wrap'],
            ]})])])],1)],1),_c('AdvantagesSection',{class:_vm.$style['web-mb-home-page__advantages']}),(_vm.showStep)?_c('WebMobBank'):_vm._e(),_c('SlidesSection'),_c('VideoSection'),_c('FeaturesSection'),_c('AboutSection'),_c('ReviewsSection'),_c('BannerSection'),_c('FaqSection')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }