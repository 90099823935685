<template>
  <div :class="$style['web-mb-page-panel']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PagePanelComponent",
};
</script>

<style lang="scss" module>
.web-mb-page-panel {
  background-color: $page-panel-background-color;
  border-radius: $page-panel-border-radius;
  padding: $page-panel-y-indent $page-panel-x-indent;

  @media (min-width: $min-md) {
    border-radius: $page-panel-border-radius-md;
    padding: $page-panel-y-indent-md $page-panel-x-indent-md;
  }

  @media (min-width: $min-lg) {
    padding: $page-panel-y-indent-lg $page-panel-x-indent-lg;
  }
}
</style>
