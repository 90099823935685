const routes = [
  {
    name: "home",
    title: "Home",
    path: "/",
    meta: {
      title: "NOVA | Boost your employee finacial wellbeing",
      description:
        "Welcome to Nova, Pakistan's premier Earned Wage Access partner for businesses. Streamline payroll processing, optimise productivity and boost retention with ease.",
      og: {
        title: "NOVA | Boost your employee finacial wellbeing",
        descr:
          "Welcome to Nova, Pakistan's premier Earned Wage Access partner for businesses. Streamline payroll processing, optimise productivity and boost retention with ease.",
        img: "",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/employers/HomePage.vue"),
  },
  {
    name: "employers",
    title: "For Employers",
    path: "/for-employers",
    meta: {
      title: "For Employers | NOVA",
      description:
        "Welcome to Nova, Pakistan's premier Earned Wage Access partner for businesses. Streamline payroll processing, optimise productivity and boost retention with ease.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      header: {
        show: true,
        class: "",
      },
      footer: {
        show: true,
        class: "",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/employers/EmployersPage.vue"),
  },
  {
    name: "about-us",
    title: "About Us",
    path: "/about-us",
    meta: {
      title: "About Us | NOVA",
      description:
        "We're a team of tech-savvy professionals aiming to bring transformative digital solutions to conventional financing services in Pakistan.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    submenu: true,
    component: () =>
      import("@/components/pakistan/site/pages/employers/AboutPage.vue"),
  },
  {
    name: "Insights",
    title: "Insights",
    path: "/insights",
    meta: {
      title: "Insights | NOVA",
      description:
        "Discover Earned Wage Access, financial education, and money management insights on our blog.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    submenu: true,
    component: () =>
      import("@/components/pakistan/site/pages/employers/InsightsPage.vue"),
  },
  {
    name: "Careers",
    title: "Careers",
    path: "/careers",
    meta: {
      title: "Careers | NOVA",
      description:
        "Join our brilliant team of professionals to bring financial freedom to salary earners in Pakistan.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    submenu: true,
    component: () =>
      import("@/components/pakistan/site/pages/employers/CareersPage.vue"),
  },
  {
    name: "Contact Us",
    title: "Contact Us",
    path: "/contact-us",
    meta: {
      title: "Contact Us | NOVA",
      description:
        "Get NOVA by reaching out to us. We'd be more than happy to help.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    submenu: true,
    component: () =>
      import("@/components/pakistan/site/pages/employers/ConctactPage.vue"),
  },
  {
    name: "employersBlog",
    title: "EmployersBlog",
    path: "/employers/blog",
    meta: {
      title: "Blog | NOVA",
      description: "Blog | NOVA",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    children: [
      {
        path: "Blog",
        name: "Blog",
        component: () =>
          import(
            "@/components/pakistan/site/sections/employers/FintechBlog.vue"
          ),
        props: true,
      },
      {
        path: "Talent-management",
        name: "Talent-management",
        component: () =>
          import(
            "@/components/pakistan/site/sections/employers/ResourcesBlog.vue"
          ),
        props: true,
      },
      {
        path: "popular",
        name: "Popular",
        component: () =>
          import(
            "@/components/pakistan/site/sections/employers/PopularBlog.vue"
          ),
        props: true,
      },
    ],
    component: () =>
      import("@/components/pakistan/site/pages/employers/BlogPage.vue"),
  },
  {
    name: "employersArticle",
    title: "EmployersArticle",
    path: "/employers/blog/:id",
    meta: {
      title: "",
      description: "",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/employees/ArticlePage.vue"),
  },
  {
    name: "employeesHome",
    title: "EmployeesHome",
    path: "/employees",
    meta: {
      title: "For Employees | NOVA",
      description:
        "Access your hard-earned pay whenever you need it, bridging the gap between paydays. With NOVA, have a stress-free approach to managing money.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      site: "employees",
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/employees/HomePage.vue"),
  },
  {
    name: "employeesFaq",
    title: "FAQ",
    path: "/employees#faq",
    meta: {
      title: "For Employees | NOVA",
      description:
        "Access your hard-earned pay whenever you need it, bridging the gap between paydays. With NOVA, have a stress-free approach to managing money.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      site: "employees",
      header: {
        show: true,
        class: "",
      },
      footer: {
        show: true,
        class: "",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/employees/HomePage.vue"),
  },
  {
    name: "employeesBlog",
    title: "Blog",
    path: "/employees/blog",
    meta: {
      title: "Blog | NOVA",
      description: "Blog | NOVA",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      site: "employees",
      header: {
        show: true,
        class: "",
      },
      footer: {
        show: true,
        class: "",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/employees/BlogPage.vue"),
  },
  {
    name: "employeesArticle",
    title: "EmployeesArticle",
    path: "/employees/blog/:id",
    meta: {
      title: "",
      description: "",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      site: "employees",
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/employees/ArticlePage.vue"),
  },
  {
    name: "employeesContact",
    title: "Contact Us",
    path: "/employees/contact-us",
    meta: {
      title: "Contact Us | NOVA",
      description:
        "Get NOVA by reaching out to us. We'd be more than happy to help.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      site: "employees",
      header: {
        show: true,
        class: "",
      },
      footer: {
        show: true,
        class: "",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/employees/ContactPage.vue"),
  },
  {
    name: "privacy",
    title: "Privacy Policy",
    path: "/privacy-policy",
    meta: {
      title: "Privacy Policy | NOVA",
      description:
        "Find out how we keep your information safe. Check out our privacy policy to see what data we collect, how we use it, and the steps we take to protect your privacy. We're committed to being open and making sure your online experience is secure.",
      og: {
        title: "",
        descr: "",
        img: "",
      },
    },
    nav: {
      header: {
        show: false,
        class: "",
      },
      footer: {
        show: false,
        class: "selenium-footer-docs-privacy-policy",
      },
    },
    component: () =>
      import("@/components/pakistan/site/pages/PrivacyPolicyPage.vue"),
  },
];

export default routes;
