import API, { baseConfig } from "@/services/index";

class LmsAPI extends API {
  constructor() {
    let baseURL = baseConfig.baseURL;
    const parsedUrl = new URL(window.location.href);
    const deprecatedBaseUrl = parsedUrl.searchParams.get("b");

    if (deprecatedBaseUrl) {
      baseURL = atob(deprecatedBaseUrl);
    }
    super({ baseURL, useJuicyScore: true });
  };
  
  async fetchCalcLimit(data = {}) {
    return await this.requestWithTokens({
      url: "/lms/limitRecalculationWeb",
      method: "POST",
      data,
    });
  }

  async otpRemainingTime() {
    return await this.requestWithTokens({
      url: "/lms/otp/remainingTime",
      method: "POST",
      data: {},
    });
  }

  async otpGenerate() {
    return await this.requestWithTokens({
      url: "/lms/otp/generate",
      method: "POST",
      data: {},
    });
  }

  async otpRemainingAttempts() {
    return await this.requestWithTokens({
      url: "/lms/otp/remainingAttempts",
      method: "POST",
      data: {},
    });
  }

  async otpValidate({ code }) {
    return await this.requestWithTokens({
      url: "/lms/otp/validate",
      method: "POST",
      data: { code },
    });
  }

  async numberOfAttemptsOkyc() {
    return await this.requestWithTokens({
      url: "/lms/numberOfAttemptsOkyc",
      method: "GET",
    });
  }

  async okycInitiateKycAuto() {
    return await this.requestWithTokens({
      url: "/lms/apibpms/DigitapOkyc/OkycInitiateKycAuto",
      method: "POST",
      data: {},
    });
  }

  async okycSubmitOtp({ code }) {
    return await this.requestWithTokens({
      url: "/lms/apibpms/DigitapOkyc/OkycSubmitOtp",
      method: "POST",
      data: { "otp": code },
    });
  }

  async okycResendOtp() {
    return await this.requestWithTokens({
      url: "/lms/apibpms/DigitapOkyc/OkycResendOtp",
      method: "POST",
      data: {},
    });
  }
}

const LmsApi = new LmsAPI({});
export default LmsApi;
