<template>
  <!-- FAQ -->
  <SectionComponent id="faq">
    <ContainerComponent>
      <SectionHeaderComponent>
        <HeadingComponent level="h2" not-semantic
          >Frequently asked questions</HeadingComponent
        >
      </SectionHeaderComponent>
      <div :class="$style['web-mb-faq-section__expansion-panels']">
        <div
          v-for="item in faq"
          :key="item.id"
          :class="$style['web-mb-faq-section__expansion-panel']"
          :style="{
            display:
              !showAll && item.id <= 6 ? 'block' : showAll ? 'block' : 'none',
          }"
        >
          <div
            :class="[
              $style['web-mb-faq-section__expansion-panel-header'],
              {
                [$style['web-mb-faq-section__expansion-panel-header--toggle']]:
                  item.show,
              },
            ]"
            @click="toggleItem(item.id)"
          >
            <div>{{ item.question }}</div>
            <div
              :class="[
                $style['web-mb-faq-section__expansion-panel-icon'],
                {
                  [$style['web-mb-faq-section__expansion-panel-icon--toggle']]:
                    item.show,
                },
                'selenium-main-block-questions-section-expansion-panel-' +
                  item.id +
                  '-button-arrow',
              ]"
            ></div>
          </div>
          <div
            v-if="item.show"
            :class="[
              $style['web-mb-faq-section__expansion-panel-content'],
              'selenium-main-block-questions-section-expansion-panel-answer-' +
                item.id,
            ]"
            v-html="item.answer"
          ></div>
        </div>
        <div
          :class="[
            $style['web-mb-faq-section__expansion-panels-toggle'],
            'selenium-main-block-questions-button-show-' +
              [showAll ? 'less' : 'more'],
          ]"
          @click="handleClick"
        >
          Show {{ showAll ? "less" : "more" }}
        </div>
      </div>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import SectionComponent from "@/components/ui/SectionComponent.vue";
import SectionHeaderComponent from "@/components/ui/SectionHeaderComponent.vue";

import HeadingComponent from "@/components/ui/elements/HeadingComponent.vue";

import data from "@/data/faq.json";

export default {
  name: "FaqSection",
  components: {
    ContainerComponent,
    SectionComponent,
    SectionHeaderComponent,
    HeadingComponent,
  },
  data: () => {
    return {
      // @See https://www.textfixer.com/html/html-list-generator.php
      faq: data?.faq,
      showAll: false,
    };
  },
  methods: {
    toggleItem(id) {
      this.faq.forEach((item) => {
        if (item.id === id) {
          item.show = !item.show;
        } else {
          item.show = false;
        }
      });
    },
    handleClick() {
      this.showAll = !this.showAll;
    },
  },
};
</script>

<style lang="scss" module>
.web-mb-faq-section {
  &__expansion {
    &-panels {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &-toggle {
        text-align: center;
        padding-top: 1rem;
        color: #b51ace;
        font-weight: 600;
        cursor: pointer;
      }
    }

    &-panel {
      background-color: #ffffff;
      border-radius: 1.5rem;

      &-header {
        padding: 2rem 1rem;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;

        &--toggle {
          padding-bottom: 1.5rem;
        }
      }

      &-icon {
        height: 1.5rem;
        width: 1.5rem;
        background-image: url(../../../assets/icons/chevron-faq-icon.svg);
        background-repeat: no-repeat;
        background-position: center center;
        transition: transform 0.15s ease-in-out;

        &--toggle {
          transform: rotate(180deg);
        }
      }

      &-content {
        border-top: 1px solid #e9ecf8;
        margin: 0 1rem 2rem 1rem;
        padding-top: 1.5rem;
        font-size: 0.875em;

        ul {
          margin-left: 1.65rem;
        }
      }
    }
  }

  @media (min-width: $min-md) {
    &__expansion {
      &-panel {
        &-header {
          padding-right: 2rem;
          padding-left: 2rem;
        }

        &-content {
          margin-left: 2rem;
          margin-right: 2rem;
        }
      }
    }
  }
}
</style>
