const initLiveChatWidget = () => {
  const liveWidgetCdnUrl = "https://cdn-india.2pp.dev/livewidget/"; // Не забывайте про / в конце
  const webchatSourcesUrl =
    "https://livechat.vivamoney.in/design/defaulttheme/js/widgetv2/index.js?";
  const lhcBaseUrl = "https://livechat.vivamoney.in/";

  window.LHC_API = {
    args: {
      mode: "widget",
      wheight: 450,
      wwidth: 250,
      theme: new URLSearchParams(window.location.search).get("theme") ?? "2",
      lhc_base_url: lhcBaseUrl,
      leaveamessage: true,
      check_messages: false,
    },
  };

  window.liveWidgetConfig = {
    styles: {
      padding: 48, // Отступ от краев экрана
      spacing: 1.1, // Коэфицент расстояния между элементами относительно 5rem
      menuBtnSize: 80, // Размер главной кнопки
      serviceBtnSize: 64, // Размер вспомогательных кнопок сервисов
      position: "right", // left | center | right
    },
    url: liveWidgetCdnUrl, // Тот же самый URL, что и liveWidgetCdnUrl;
    webchatSourcesUrl: webchatSourcesUrl,
    buttons: {
      whatsapp: {
        isHidden: true,
        // handler() {
        //   console.log("custom handler wapp");
        // },
      },
      contacts: {
        isHidden: true,
        // handler() {
        //   console.log("custom handler contacts");
        // },
      },
    },
  };

  const script = document.createElement("script");
  script.src = liveWidgetCdnUrl + "index.js";
  document.body.appendChild(script);
};

export default initLiveChatWidget;
