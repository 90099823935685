<template>
  <div :class="$style['web-mb-feature__feature']">
    <ImgComponent :src="'/img/' + feature.icon" :alt="feature.alt" />
    <div>
      <div :class="$style['web-mb-feature__feature-title']">
        {{ feature.title }}
      </div>
      <div :class="$style['web-mb-feature__feature-content']">
        {{ feature.content }}
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "@/components/ui/ImgComponent.vue";

export default {
  name: "FeatureComponent",
  components: {
    ImgComponent,
  },
  props: {
    feature: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
.web-mb-feature {
  &__feature {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    &-title {
      font-weight: 600;
    }

    &-content {
      color: #5a717c;
      font-size: 0.944em;
    }
  }
}
</style>
