<template>
  <div
    :class="[
      $style['web-mb-section-header'],
      { [$style['web-mb-section-header--is-wider']]: isWider },
    ]"
  >
    <div :class="[$style['web-mb-section-header__text']]"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "SectionHeaderComponent",
  props: {
    isWider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.web-mb-section-header {
  padding-bottom: 1.5rem;

  &__text {
    display: inline-block;
    background: #a92dd2;
    background: linear-gradient(
      93.21deg,
      #a92dd2 11.61%,
      #9747ff 24.44%,
      #339c89 64.47%,
      #43b02a 92.93%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &--is-wider {
    margin: 0 0.5rem;
  }

  @media (min-width: $min-md) {
    padding-bottom: 2rem;
  }
}
</style>
