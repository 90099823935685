<template>
  <header
    ref="header"
    :class="[
      $style['web-mb-header'],
      { [$style['web-mb-header--is-sticky']]: isSticky },
    ]"
  >
    <ContainerComponent :style="{ maxWidth: 'none' }">
      <div :class="$style['web-mb-header__panel']">
        <ContainerComponent>
          <div :class="$style['web-mb-header__row']">
            <a href="/" class="selenium-header-menu-list-href-logo">
              <ImgComponent
                :src="logoImg"
                alt="VIVA Money - Instant Personal Loan App"
                :img-style="{ width: '85px', height: 'auto' }"
              />
            </a>
            <nav :class="$style['web-mb-header__nav']">
              <template v-for="route in routes">
                <div
                  v-if="route.nav.header.show"
                  :key="route.name"
                  :class="[
                    {
                      [$style['web-mb-header__active-page']]:
                        route.path === $route.path,
                    },
                  ]"
                >
                  <a :href="route.path" :class="route.nav.header.class">{{
                    route.title
                  }}</a>
                </div>
              </template>
            </nav>
            <div
              :class="[
                $style['web-mb-header__mb-nav-icon'],
                { [$style['web-mb-header__mb-nav-icon--is-open']]: isOpen },
              ]"
              @click="toggleMenu"
            ></div>
          </div>
          <nav
            :class="[
              $style['web-mb-header__row'],
              $style['web-mb-header__mb-nav'],
              { [$style['web-mb-header__mb-nav--is-open']]: isOpen },
            ]"
          >
            <template v-for="route in routes">
              <div
                v-if="route.nav.header.show"
                :key="route.name"
                :class="[
                  {
                    [$style['web-mb-header__active-page']]:
                      route.path === $route.path,
                  },
                ]"
              >
                <a :href="route.path">{{ route.title }}</a>
              </div>
            </template>
          </nav>
        </ContainerComponent>
      </div>
    </ContainerComponent>
  </header>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";

import logoImg from "@/assets/images/site/logo-img.svg";

import routes from "@/router/routes.india";

export default {
  name: "HeaderComponent",
  components: {
    ContainerComponent,
    ImgComponent,
  },
  data: () => {
    return {
      routes,
      logoImg,
      isOpen: false,
      isSticky: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.some);
  },
  destroyed() {
    window.removeEventListener("scroll", this.some);
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    some() {
      this.isSticky = window.scrollY > 0;
    },
  },
  // event resize isOpen
};
</script>

<style lang="scss" module>
.web-mb-header {
  margin: 1.5rem $section-x-indent;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: margin 0.15s ease-in-out;
  transform: translateZ(0);
  align-self: stretch;

  &__panel {
    background-color: #ffffff;
    border-radius: 1.5rem;
    padding: 0.75rem 1.5rem;
    box-shadow: 0px 5px 30px rgba(79, 105, 118, 0.08);
  }

  &__row {
    display: flex;
    justify-content: space-between;
    gap: 3.5rem;
  }

  &__nav {
    display: none;
    font-weight: 500;
  }

  &__mb-nav {
    display: none;
    font-size: 1.375em;
    font-weight: 600;

    &-icon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url(../../assets/icons/menu-icon.svg);
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;

      &--is-open {
        background-image: url(../../assets/icons/close-menu-icon.svg);
      }
    }

    &--is-open {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding-bottom: 0.75rem;
      padding-top: 1.5rem;
    }
  }

  &__nav a:link,
  &__nav a:visited,
  &__mb-nav a:link,
  &__mb-nav a:visited,
  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active,
  &__mb-nav a:hover,
  &__mb-nav a:focus,
  &__mb-nav a:active {
    color: #0e1a22;
    text-decoration: none;
    transition: color 0.15s ease-in-out;
  }

  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active,
  &__mb-nav a:hover,
  &__mb-nav a:focus,
  &__mb-nav a:active {
    color: #b51ace;
  }

  &__active-page a:link,
  &__active-page a:visited {
    color: #b51ace;
  }

  &__active-page a:hover,
  &__active-page a:focus,
  &__active-page a:active {
    color: #0e1a22;
  }

  &--is-sticky &__panel {
    border-radius: 0;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    transition: border-radius 0.3s ease-in-out;
  }

  &--is-sticky {
    margin-left: 0;
    margin-right: 0;
    transition: margin 0.15s ease-in-out;
  }

  @media (min-width: $min-md) {
    margin: 1.5rem $section-x-indent-md;
    transition: margin 0.15s ease-in-out;

    &--is-sticky {
      margin-left: 0;
      margin-right: 0;
      transition: margin 0.3s ease-in-out;
    }

    &__panel {
      padding: 1.5rem 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 4rem;
    }

    &--is-sticky &__panel {
      border-bottom-left-radius: 2.5rem;
      border-bottom-right-radius: 2.5rem;
    }

    &__nav {
      display: flex;
      gap: 3.5rem;
    }

    &__mb-nav,
    &__mb-nav-icon {
      display: none;
    }
  }

  @media (min-width: $min-lg) {
    margin: 1.5rem $section-x-indent-lg;
    transition: margin 0.2s ease-in-out;

    &--is-sticky {
      margin-left: 0;
      margin-right: 0;
      transition: margin 0.3s ease-in-out;
    }
  }
}
</style>
