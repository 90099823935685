import routesIndia from "@/router/routes.india";
import routesPakistan from "@/router/routes.pakistan";

// KILLME: Чтобы импортировать пути по названи динамически, нужно использовать динамический импорт
// Динамический испорт = промис = падение роутера из-за асинхронности
// Дублируем мап из конфига вью чтобы работало хоть как-то
// Реакт лучше
export default {
  "india": routesIndia,
  "pakistan": routesPakistan,
  // ....
};
