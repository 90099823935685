<template>
  <div :class="$style['web-mb-banner-panel']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BannerPanelComponent",
};
</script>

<style lang="scss" module>
.web-mb-banner-panel {
  background: linear-gradient(
    31.01deg,
    #a92dd2 -42.78%,
    #a92dd2 7.55%,
    #9747ff 22.93%,
    #339c89 70.91%,
    #43b02a 105.02%
  );
  color: #ffffff;
  border-radius: 1.5rem;

  @media (min-width: $min-md) {
    border-radius: 2rem;
  }

  @media (min-width: $min-lg) {
    border-radius: 2.5rem;
  }
}
</style>
