import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import vuetify from "@/plugins/vuetify";

import store from "@/store";
import uikitBpm from "uikit-bpm";
import "!!vue-style-loader!css-loader!uikit-bpm/uikit-bpm.css";

import "@/styles/main.scss";

import initJuicyPixel from "@/libs/juicyPixel";

Vue.config.productionTip = false;

Vue.use(uikitBpm);

// Disable all logs
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  vuetify,
}).$mount("#app");

initJuicyPixel({});
