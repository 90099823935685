import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

import OTP4DigsModule from "./OTP4Digs.module";
import OTP6DigsModule from "./OTP6Digs.module";


const modules = {
  otp4Digs: OTP4DigsModule,
  otp6Digs: OTP6DigsModule,
};

const state = {
  currentScreen: null,
  screenWidgets: null,
  updateCurrentScreenInterval: null,
  currentScreenData: {},
};

export default {
  namespaced: true,
  modules,
  state,
  actions,
  mutations,
  getters,
};
