<template>
  <SectionComponent
    :disable-vertical-margins="isModule"
  >
    <ContainerComponent>
      <LoaderComponent v-show="isNodeLoading && !isHiddenLoaders" />
      <component
        :is="screenComponent"
        v-show="!isNodeLoading || isHiddenLoaders"
        :key="`key-${screenKey}`"
        :is-dev="isDev"
      />
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import SectionComponent from "@/components/ui/SectionComponent.vue";
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import LoaderComponent from "@/components/app/ui/LoaderComponent.vue";
import eventBus from "@/utils/eventBus";

export default {
  name: "WebMobBank",
  components: {
    SectionComponent,
    ContainerComponent,
    LoaderComponent,
  },
  data: () => {
    return {
      isDev: process.env.VUE_APP_ENV === "dev",
      screenKey: 0,
    };
  },
  computed: {
    screenComponent() {
      return this.$store.getters["node/screen/screenComponent"];
    },
    isNodeLoading() {
      return this.$store.state.node.isNodeLoading;
    },
    isHiddenLoaders() {
      return this.$store.state.node.useBackgroundSync;
    },
    isModule() {
      return window.location.pathname === "/web-mob-bank-fake-route"
    }
  },
  watch: {
    isNodeLoading: {
      handler: function(val, oldVal) {
        if (val === false && oldVal === true) {
          this.$nextTick( async () => {
            await this.updateScreenPostMessage();
          });
        }
      }
    }
  },
  async mounted() {
    await this.$store.dispatch("node/initializeNode");
    this.registerListeners();
  },
  beforeDestroy() {
    this.unregisterListeners();
  },
  methods: {
    updateScreenKey() {
      if (this.isHiddenLoaders) return;
      
      this.screenKey += 1;
    },
    registerListeners() {
      eventBus.$on("rerender-current-screen", () => {
        this.updateScreenKey();
      });
    },
    unregisterListeners() {
      eventBus.$off('rerender-current-screen');
    },
    async updateScreenPostMessage() {
      const screenHeight = window.document.getElementById("app")?.clientHeight;

      const postData = {
        message: "screen-ready",
        value: {
          screenHeight: screenHeight + "px",
        }
      };
      
      console.log("postData", postData)

      await window.parent.postMessage(postData, "*");
    }
  },
};
</script>
