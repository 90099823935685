import steps from "@/components/app/steps";

const screenName = (state) => {
  return state.currentScreen?.name || "";
};
const screenComponent = (state, getters) => {
  return steps[getters.screenName]?.component || null;
};

const screenProperties = (state) => {
  return state.currentScreen?.properties || {};
};

const screenFields = (state) => {
  return state.currentScreen?.nodeFields || [];
};

const currentScreenData = (state) => {
  return state.currentScreenData || {};
};

export default {
  screenComponent,
  screenProperties,
  screenFields,
  screenName,
  currentScreenData,
};
