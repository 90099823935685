import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

import screenModule from "./modules/screen";

const state = {
  isNodeLoading: false,
  isFetchingScreen: false,
  currentNode: null,
  useBackgroundSync: false,
  needToReRenderScreen: false,
  // TODO: tasks on transition error
  tasksBeforeTransition: [],
  tasksAfterTransition: [],
};

const modules = {
  screen: screenModule,
};

export default {
  namespaced: true,
  modules,
  state,
  actions,
  mutations,
  getters,
};
