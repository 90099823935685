<template>
  <header
    ref="header"
    :class="[
      $style['web-mb-header'],
      { [$style['web-mb-header--is-sticky']]: isSticky },
      { [$style['web-mb-header--is-open']]: isOpen },
    ]"
  >
    <ContainerComponent
      :style="{ maxWidth: 'none' }"
      :class="$style['web-mb-header__container']"
    >
      <div :class="$style['web-mb-header__panel']">
        <ContainerComponent>
          <div :class="$style['web-mb-header__row']">
            <div :class="$style['web-mb-header__column']">
              <a href="/">
                <ImgComponent
                  :src="logoImg"
                  alt="NOVA Logo"
                  :img-style="{ width: '85px', height: 'auto' }"
                />
              </a>
              <nav :class="$style['web-mb-header__nav']">
                <template v-for="route in currentRoutes">
                  <div
                    v-if="route.nav.header.show && !route.submenu"
                    :key="route.name"
                    :class="[
                      {
                        [$style['web-mb-header__active-page']]:
                          route.path === $route.path,
                      },
                    ]"
                  >
                    <a :href="route.path" :class="route.nav.header.class">{{
                      route.title
                    }}</a>
                  </div>
                </template>
                <DropdownMenu v-if="!isEmployees" />
                <div :class="$style['web-mb-header__double-divider']">
                  {{ isEmployees ? "|" : "||" }}
                </div>
                <a
                  :class="$style['web-mb-header__source-wrap']"
                  :href="isEmployees ? '/' : '/employees'"
                  target="_blank"
                  >{{ isEmployees ? "For Employer" : "For Employees" }}
                </a>
              </nav>
            </div>
            <div
              v-if="isEmployees"
              :class="[
                $style['web-mb-header__column'],
                $style['web-mb-header__actions'],
              ]"
            >
              <bpm-button
                :class="$style['web-mb-header__button']"
                background-color="#043AD9"
                @click="openModal"
              >
                Get App
              </bpm-button>
            </div>
            <div
              v-else
              :class="[
                $style['web-mb-header__column'],
                $style['web-mb-header__actions'],
                $style['web-mb-header__btns-wrap'],
              ]"
            >
              <ButtonLinkComponent
                href="https://dev.pk.2pp.dev/back-office/login"
                bg-color="#DEF0FD"
                color="#043AD9"
                >Log in</ButtonLinkComponent
              >
              <ButtonLinkComponent
                href="#book-a-demo"
                bg-color="#043AD9"
                color="#ffffff"
                >Book a Demo</ButtonLinkComponent
              >
            </div>
            <div
              :class="[
                $style['web-mb-header__mb-nav-icon'],
                { [$style['web-mb-header__mb-nav-icon--is-open']]: isOpen },
              ]"
              @click="toggleMenu"
            ></div>
          </div>
          <nav
            :class="[
              $style['web-mb-header__row'],
              $style['web-mb-header__mb-nav'],
              { [$style['web-mb-header__mb-nav--is-open']]: isOpen },
            ]"
          >
            <template v-for="route in currentRoutes">
              <div
                v-if="route.nav.header.show && !route.submenu"
                :key="route.name"
                :class="[
                  {
                    [$style['web-mb-header__active-page']]:
                      route.path === $route.path,
                  },
                ]"
                @click="handleClick"
              >
                <a :href="route.path">{{ route.title }}</a>
              </div>
            </template>

            <DropdownMenu v-if="!isEmployees" />

            <a
              :class="$style['web-mb-header__source-wrap']"
              :href="isEmployees ? '/' : '/employees'"
              target="_blank"
              >{{ isEmployees ? "For Employer" : "For Employees" }}
            </a>
            <bpm-button
              v-if="isEmployees"
              fullwidth
              background-color="#043AD9"
              @click="openModal"
              >Get App</bpm-button
            >
            <div
              v-else
              :class="$style['web-mb-header__mb-nav-btn-wrap']"
              @click="handleClick">
              <ButtonLinkComponent
                href="https://dev.pk.2pp.dev/back-office/login"
                bg-color="#DEF0FD"
                color="#043AD9"
                >Log in</ButtonLinkComponent
              >
              <ButtonLinkComponent
                href="#book-a-demo"
                bg-color="#043AD9"
                color="#ffffff"
                >Book a Demo</ButtonLinkComponent
              >
            </div>
          </nav>
        </ContainerComponent>
      </div>
    </ContainerComponent>
    <div
      :class="[
        $style['web-mb-header__background'],
        { [$style['web-mb-header__background_visible']]: isOpen },
      ]"
    />
    <GetAppModalComponent :is-open="isOpenModal" @close="closeModal" />
  </header>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";
import DropdownMenu from "@/components/pakistan/ui/DropdownMenu.vue";
import ButtonLinkComponent from "@/components/pakistan/ui/ButtonLinkComponent.vue";
import GetAppModalComponent from "@/components/pakistan/ui/GetAppModalComponent.vue";

import logoImg from "@/assets/images/pakistan/logo-img.svg";

import routes from "@/router/routes.pakistan";

export default {
  name: "HeaderComponentPakistan",
  components: {
    DropdownMenu,
    ContainerComponent,
    ImgComponent,
    GetAppModalComponent,
    ButtonLinkComponent,
  },
  data: () => {
    return {
      routes,
      logoImg,
      isOpen: false,
      isSticky: false,
      isOpenModal: false,
    };
  },
  computed: {
    isEmployees() {
      return this.$route.path.includes("/employees");
    },
    // todo
    currentRoutes() {
      return this.isEmployees
        ? this.routes.filter((route) => route.nav.site === "employees")
        : this.routes.filter((route) => route.nav.site !== "employees"); // employers
    },
  },
  mounted() {
    window.addEventListener("scroll", this.some);
  },
  destroyed() {
    window.removeEventListener("scroll", this.some);
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    some() {
      const rect = this.$refs.header.getBoundingClientRect();
      this.isSticky = rect.top <= 0;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    openModal() {
      // ?
      this.isOpen = false;
      this.isOpenModal = true;
    },
    closeModal() {
      this.isOpenModal = false;
    },
    handleClick( e ) {
      if ( e.target.tagName === 'A' ) {
        this.toggleMenu();
      }
    }
  },
  // event resize isOpen
};
</script>

<style lang="scss" module >
.web-mb-header {
  margin-top: 1.5rem;
  z-index: 1000;

  &__panel {
    padding: 1rem $section-x-indent 1rem $section-x-indent;
  }

  position: sticky;
  top: 0;

  &--is-open {
    top: 1.5rem;
  }

  &__container {
    position: relative;
    z-index: 2;
    background-color: #fff;
  }

  &--is-open &__container {
    border-radius: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &--is-open &__panel {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3.5rem;
  }

  &__column {
    display: flex;
    align-items: flex-start;
  }

  &__nav {
    display: none;
    font-weight: 500;
  }

  &__mb-nav {
    display: none;
    font-size: 1.375em;
    font-weight: 600;

    &-icon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url(../../../assets/icons/menu-icon.svg);
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;

      &--is-open {
        background-image: url(../../../assets/icons/close-menu-icon.svg);
      }
    }

    &--is-open {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding-top: 3rem;
    }
  }

  &__nav a:link,
  &__nav a:visited,
  &__mb-nav a:link,
  &__mb-nav a:visited,
  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active,
  &__mb-nav a:hover,
  &__mb-nav a:focus,
  &__mb-nav a:active {
    color: #0e1a22;
    text-decoration: none;
    transition: color 0.15s ease-in-out;
  }

  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active,
  &__mb-nav a:hover,
  &__mb-nav a:focus,
  &__mb-nav a:active {
    color: #043ad9;
  }

  &__source-wrap:hover,
  &__source-wrap:focus,
  &__source-wrap:active {
    color: #043ad9;
    &::after {
      content: url(../../../assets/icons/source-icon-2.svg);
    }
  }

  &__active-page a:link,
  &__active-page a:visited {
    color: #043ad9;
  }

  &__active-page a:hover,
  &__active-page a:focus,
  &__active-page a:active {
    color: #0e1a22;
  }

  &__source-wrap {
    display: flex;
    align-items: center;

    &::after {
      width: 24px;
      height: 24px;
      content: url(../../../assets/icons/source-icon-1.svg);
      margin-top: 0.1rem;
      margin-left: 0.3rem;
    }
  }

  &__mb-nav-btn-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__double-divider {
    color: #96adb8;
    font-weight: 300;
  }

  &__background {
    position: fixed;
    top: 0;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
    display: none;

    &_visible {
      display: block;
    }
  }

  &__actions {
    display: none;
  }

  &__btns-wrap {
    gap: 1rem;
  }

  @media (min-width: $min-md) {
    &__panel {
      padding: 1rem $section-x-indent-md 1rem $section-x-indent-md;
    }

    &__panel {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__nav {
      display: flex;
      gap: 25px;
      margin-left: 30px;
    }

    &__actions {
      display: flex;
    }

    &__button {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__mb-nav,
    &__mb-nav-icon {
      display: none;
    }
  }

  @media (min-width: $min-lg) {
    &__panel {
      padding: 1rem $section-x-indent-lg 1rem $section-x-indent-lg;
    }
  }
}
</style>
