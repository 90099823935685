const setCurrentScreen = (state, screen) => {
  state.currentScreen = screen;
};

const setScreenData = (state, data) => {
  state.currentScreenData = data;
};

const setScreenWidgets = (state, widgets) => {
  state.screenWidgets = widgets;
};

const setUpdateCurrentScreenInterval = (state, interval) => {
  state.updateCurrentScreenInterval = interval;
};

export default {
  setCurrentScreen,
  setScreenData,
  setScreenWidgets,
  setUpdateCurrentScreenInterval,
};
