<template>
  <footer :class="$style['web-mb-footer']">
    <ContainerComponent>
      <div :class="$style['web-mb-footer__content']">
        <div :class="$style['web-mb-footer__row']">
          <div :class="$style['web-mb-footer__logo-wrap']">
            <ImgComponent
              :src="logoImg"
              alt="VIVA Logo"
              :img-style="{ width: '85px', height: 'auto' }"
            />
            <div :class="$style['web-mb-footer__mb-email']">
              chat@vivamoney.in
            </div>
          </div>
          <nav :class="$style['web-mb-footer__nav']">
            <template v-for="route in routes">
              <div v-if="route.nav.footer.show" :key="route.name">
                <a :href="route.path" :class="route.nav.footer.class">{{
                  route.title
                }}</a>
              </div>
            </template>
            <div>
              <a
                :href="
                  ($route.path === '/' ||
                  $route.path === '/personal-line-of-credit'
                    ? ''
                    : '/') + '#faq'
                "
                class="selenium-footer-pages-faq"
                >FAQ</a
              >
            </div>
          </nav>
        </div>
        <div :class="$style['web-mb-footer__row']">
          <div :class="$style['web-mb-footer__email']">
            <div :class="$style['web-mb-footer__email-name']">Email</div>
            <div :class="$style['web-mb-footer__email-value']">
              chat@vivamoney.in
            </div>
          </div>
          <div :class="$style['web-mb-footer__download-wrap']">
            <a href="https://vivam.onelink.me/0rtl/zw1ld90q" target="_blank">
              <ImgComponent
                :src="googlePlayImg"
                :img-style="{ width: 'auto', height: '56px' }"
                alt="Get VIVA Money from Google Play Store"
              />
            </a>
            <a href="https://vivam.onelink.me/0rtl/zw1ld90q" target="_blank">
              <ImgComponent
                :src="appStoreImg"
                :img-style="{ width: 'auto', height: '56px' }"
                alt="Get VIVA Money from Apple App Store"
              />
            </a>
            <ImgComponent
              :class="$style['web-mb-footer__qr-code-img']"
              :src="qrCodeImg"
              :img-style="{
                width: '56px',
                height: '56px',
                borderRadius: '0.5rem',
              }"
              alt="Scan VIVA Money QR code"
            />
          </div>
        </div>

        <div :class="$style['web-mb-footer__info']">
          <div :class="$style['web-mb-footer__info-links']">
            <div :class="$style['web-mb-footer__info-data']">
              <a
                href="/terms-and-conditions"
                class="selenium-footer-docs-terms-and-conditions"
                >Terms and Conditions</a
              >
              <a
                href="/privacy-policy"
                class="selenium-footer-docs-privacy-policy"
                >Privacy Policy</a
              >
              <a
                href="/fair-practices-code"
                class="selenium-footer-docs-fair-practices-code"
                >Fair Practices Code</a
              >
            </div>
            <div :class="$style['web-mb-footer__social']">
              <a
                href="https://www.instagram.com/vivamoney.in/"
                target="_blank"
                class="selenium-footer-contacts-instagram"
              >
                <ImgComponent
                  :src="instagramIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/vivamoney"
                target="_blank"
                class="selenium-footer-contacts-linkedin"
              >
                <ImgComponent
                  :src="linkedinIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="linkedin"
                />
              </a>
              <a
                href="https://www.facebook.com/vivamoney"
                target="_blank"
                class="selenium-footer-contacts-facebook"
              >
                <ImgComponent
                  :src="facebookIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="facebook"
                />
              </a>
              <a
                href="https://twitter.com/vivamoneyindia"
                target="_blank"
                class="footer-contacts-twitter"
              >
                <ImgComponent
                  :src="twitterIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="twitter"
                />
              </a>
              <a
                href="https://www.youtube.com/@Vivamoneyindia"
                target="_blank"
                class="selenium-footer-contacts-youtube"
              >
                <ImgComponent
                  :src="youtubeIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="youtube"
                />
              </a>
              <a
                href="mailto:chat@vivamoney.in"
                class="selenium-footer-contacts-mail"
              >
                <ImgComponent
                  :src="mailIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="mail"
                />
              </a>
            </div>
          </div>
          <div :class="$style['web-mb-footer__divider']"></div>
          <div :class="$style['web-mb-footer__info-about']">
            <div :class="$style['web-mb-footer__info-about-col-first']">
              <div>
                <div>NBFC Partner</div>
                <div :class="$style['web-mb-footer__info-links']">
                  <a href="https://fincfriends.com/" target="_blank"
                    >FincFriends Private Limited</a
                  >
                </div>
              </div>
              <div>
                <div>CIN: U36737286KA74834</div>
                <div>©2024 VIVA MONEY SOLUTION PVT LTD</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerComponent>
  </footer>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";

// images
import logoImg from "@/assets/images/site/logo-img.svg";
import googlePlayImg from "@/assets/images/site/google-play-img.webp";
import appStoreImg from "@/assets/images/site/app-store-img.webp";
import qrCodeImg from "@/assets/images/site/mb-qr-code.png";

// icons
import instagramIcon from "@/assets/icons/social/instagram-icon.svg";
import linkedinIcon from "@/assets/icons/social/linkedin-icon.svg";
import facebookIcon from "@/assets/icons/social/facebook-icon.svg";
import twitterIcon from "@/assets/icons/social/twitter-icon.svg";
import youtubeIcon from "@/assets/icons/social/youtube-icon.svg";
import mailIcon from "@/assets/icons/social/mail-icon.svg";

import routes from "@/router/routes.india";

export default {
  name: "FooterComponent",
  components: {
    ContainerComponent,
    ImgComponent,
  },
  data: () => {
    return {
      // FAQ (home: FAQ якорь)
      // Blog ?
      routes,
      logoImg,
      googlePlayImg,
      appStoreImg,
      qrCodeImg,
      instagramIcon,
      linkedinIcon,
      facebookIcon,
      twitterIcon,
      youtubeIcon,
      mailIcon,
    };
  },
};
</script>

<style lang="scss" module>
.web-mb-footer {
  margin-top: auto;
  background-color: #e9ecf8;
  padding: 2rem 1rem;
  border-radius: 1.5rem 1.5rem 0 0;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__divider {
    width: 100%;
    height: 0.8px;
    background: #96adb8;
    opacity: 0.5;
  }

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__logo-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
  }

  &__mb-email {
    font-weight: 600;
  }

  &__nav {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__nav a:link,
  &__nav a:visited,
  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active {
    color: #b51ace;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.15s ease-in-out;
  }

  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active {
    color: #0e1a22;
  }

  &__email {
    display: none;

    &-name {
      color: #5a717c;
      font-size: 0.667em;
    }

    &-value {
      font-weight: 600;
      font-size: 1.111em;
    }
  }

  &__download-wrap {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__qr-code-img {
    display: none;
  }

  &__info {
    display: flex;
    flex-direction: column;
    // align-items: center;
    font-size: 0.75em;
    gap: 1.5rem;
    color: #5a717c;

    a:link,
    a:visited,
    a:hover,
    a:focus,
    a:active {
      color: #5a717c;
    }
  }

  &__info-data {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__social {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &__info-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a:link,
    a:visited,
    a:hover,
    a:focus,
    a:active {
      text-decoration: underline;
      transition: color 0.15s ease-in-out;
    }

    a:hover,
    a:focus,
    a:active {
      color: #b51ace;
    }
  }

  &__info-about {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;

    &-col {
      &-first {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  @media (min-width: $min-md) {
    padding: 2rem 2.75rem;

    &__row {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__logo-wrap {
      padding: 0;
    }

    &__mb-email {
      display: none;
    }

    &__nav {
      gap: 1.5rem;
    }

    &__email {
      display: block;
    }

    &__social {
      width: auto;
      gap: 2rem;
    }

    &__info-about {
      text-align: initial;

      &-col {
        &-first {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  @media (min-width: $min-lg) {
    padding: 2rem 7rem;

    &__content {
      gap: 2rem;
    }

    &__qr-code-img {
      display: block;
    }

    &__info-data {
      flex-wrap: nowrap;
    }

    &__info-links {
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
    }

    &__info-about {
      flex-direction: row;
      justify-content: space-between;

      &-col {
        &-first {
          display: flex;
          flex: 2;
          gap: 2rem;
          justify-content: start;
        }

        &-second {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
</style>
