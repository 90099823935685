<template>
  <!-- BANNER -->
  <SectionComponent>
    <ContainerComponent>
      <BannerPanelComponent>
        <div :class="$style['web-mb-banner-section__row']">
          <div
            :class="[
              $style['web-mb-banner-section__col'],
              $style['web-mb-banner-section__content'],
            ]"
          >
            <div>
              <HeadingComponent level="h2" reset-style>
                <div :class="$style['web-mb-banner-section__header']">
                  Download VIVA
                </div>
                <div :class="$style['web-mb-banner-section__header-descr']">
                  Your Personal Loan App
                </div>
              </HeadingComponent>
              <p>
                Instantly withdraw money from your credit limit of up to
                ₹2,00,000! No paperwork, ever
              </p>
            </div>
            <div :class="$style['web-mb-banner-section__download-wrap']">
              <div :class="$style['web-mb-banner-section__qr-code-wrap']">
                <ImgComponent
                  :src="qrCodeImg"
                  :img-style="{
                    width: '128px',
                    height: '128px',
                    borderRadius: '0.5rem',
                  }"
                  alt="QR code to download the VIVA Money App"
                />
              </div>
              <div
                :class="$style['web-mb-banner-section__download-links-wrap']"
              >
                <a
                  href="https://vivam.onelink.me/0rtl/zw1ld90q"
                  target="_blank"
                >
                  <ImgComponent
                    :src="googlePlayImg"
                    :img-style="{ width: 'auto', height: '42px' }"
                    alt="Download the VIVA app from Google Play Store"
                  />
                </a>
                <a
                  href="https://vivam.onelink.me/0rtl/zw1ld90q"
                  target="_blank"
                >
                  <ImgComponent
                    :src="appStoreImg"
                    :img-style="{ width: 'auto', height: '42px' }"
                    alt="Download the VIVA App from Apple App Store"
                /></a>
              </div>
            </div>
          </div>
          <div
            :class="[
              $style['web-mb-banner-section__col'],
              $style['web-mb-banner-section__img-wrap'],
            ]"
          >
            <ImgComponent
              :src="img"
              :img-style="{
                width: '70%',
                height: 'auto',
                filter: 'drop-shadow(-8px 8px 10px #222)',
                position: 'absolute',
              }"
              alt="Download VIVA Money - India’s 1st Personal Line of Credit App to apply for Interest Free Loans"
            />
          </div>
        </div>
      </BannerPanelComponent>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import SectionComponent from "@/components/ui/SectionComponent.vue";
import BannerPanelComponent from "@/components/ui/BannerPanelComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";

import HeadingComponent from "@/components/ui/elements/HeadingComponent.vue";

import img from "@/assets/images/banner/banner-img.webp";
import qrCodeImg from "@/assets/images/site/mb-qr-code.png";
import googlePlayImg from "@/assets/images/banner/banner-google-play-img.svg";
import appStoreImg from "@/assets/images/banner/banner-app-store-img.svg";

export default {
  name: "BannerSection",
  components: {
    ContainerComponent,
    SectionComponent,
    BannerPanelComponent,
    ImgComponent,
    HeadingComponent,
  },
  data: () => {
    return {
      img,
      qrCodeImg,
      googlePlayImg,
      appStoreImg,
    };
  },
};
</script>

<style lang="scss" module>
.web-mb-banner-section {
  &__row {
    display: flex;
    flex-direction: column;
  }

  &__col {
    flex: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    margin: 1.5rem 1rem;
  }

  &__header {
    font-size: 2.222em;
    font-weight: 500;
  }

  &__header-descr {
    font-size: 0.875rem;
    padding-bottom: 1.5rem;
    font-weight: 400;
    color: #e9ecf8;
  }

  &__qr-code-wrap {
    display: none;
  }

  &__download-wrap {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-around;
  }

  &__download-links-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  &__img-wrap {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem 0 1rem;
    position: relative;
    overflow: hidden;
    min-height: 300px;
  }

  @media (min-width: $min-md) {
    &__row {
      flex-direction: row;
    }

    &__content {
      gap: 3rem;
      text-align: initial;
      margin: 4rem 0 4rem 2rem;
    }

    &__download-wrap {
      justify-content: start;
    }

    &__download-links-wrap {
      flex-direction: column;
      gap: 1.5rem;
    }

    &__img-wrap {
      min-height: 0;
    }
  }

  @media (min-width: $min-lg) {
    &__qr-code-wrap {
      display: block;
    }

    &__content {
      margin: 3.75rem 0 3.75rem 5.5rem;
    }
  }
}
</style>
