<template>
  <SectionComponent>
    <ContainerComponent>
      <SectionHeaderComponent>
        <HeadingComponent level="h2" not-semantic
          >How we work?</HeadingComponent
        >
      </SectionHeaderComponent>
      <div :class="$style['web-mb-video-section__video-wrap']">
        <iframe
          :class="[
            $style['web-mb-video-section__video'],
            'selenium-main-block-how-we-work-video-play',
          ]"
          src="https://www.youtube-nocookie.com/embed/sW6mja6nM_s?rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import SectionComponent from "@/components/ui/SectionComponent.vue";
import SectionHeaderComponent from "@/components/ui/SectionHeaderComponent.vue";

import HeadingComponent from "@/components/ui/elements/HeadingComponent.vue";

export default {
  name: "VideoSection",
  components: {
    ContainerComponent,
    SectionComponent,
    SectionHeaderComponent,
    HeadingComponent,
  },
};
</script>

<style lang="scss" module>
.web-mb-video-section {
  &__video {
    border-radius: 1.5rem;
    aspect-ratio: 2 / 1;
    width: 100%;

    &-wrap {
      display: flex;
      justify-content: center;
    }
  }

  // @media (min-width: $min-md) {
  // }

  // 100rem = 1600px
  @media (min-width: $min-lg) and (max-width: 100rem) {
    &__video {
      border-radius: 2rem;
      width: 80%;
    }
  }
}
</style>
