<template>
  <!-- ABOUT -->
  <SectionComponent>
    <ContainerComponent>
      <SectionHeaderComponent>
        <HeadingComponent level="h2" not-semantic>About us</HeadingComponent>
      </SectionHeaderComponent>
      <!-- <SectionPanelComponent> -->
      <div :class="$style['web-mb-about-section__row']">
        <div
          :class="[
            $style['web-mb-about-section__col'],
            $style['web-mb-about-section__content'],
          ]"
        >
          <p>
            Viva Money is a digital financial lending platform offering India’s
            1st Line of Credit. By partnering up with Fincfriends Private
            Limited registered with RBI, VIVA provides an affordable & easy way
            of taking a revolving credit line.
          </p>
          <p>
            Viva Money stands as a pioneering member within the Viva family, as
            it extends its presence to various countries including Mexico and
            Italy.
          </p>
          <a
            :class="[
              $style['web-mb-about-section__link-btn'],
              'selenium-main-block-about-us-button-learn-more',
            ]"
            href="/about-us"
            >Learn more</a
          >
        </div>
        <div
          :class="[
            $style['web-mb-about-section__col'],
            $style['web-mb-about-section__img-wrap'],
          ]"
        >
          <ImgComponent
            :src="aboutImgMd"
            :class="$style['web-mb-about-section__img-sm']"
            alt="VIVA Money team"
          />
          <ImgComponent
            :src="aboutImgMd"
            :class="$style['web-mb-about-section__img-md']"
            alt="VIVA Money team"
          />
        </div>
      </div>
      <!-- </SectionPanelComponent> -->
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import SectionComponent from "@/components/ui/SectionComponent.vue";
// import SectionPanelComponent from '@/components/ui/SectionPanelComponent.vue'
import SectionHeaderComponent from "@/components/ui/SectionHeaderComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";

import HeadingComponent from "@/components/ui/elements/HeadingComponent.vue";

// import aboutImgSm from "@/assets/images/site/about-img-md-new.png";
import aboutImgMd from "@/assets/images/site/about-img-md.jpg";

export default {
  name: "AboutSection",
  components: {
    ContainerComponent,
    SectionComponent,
    // SectionPanelComponent,
    SectionHeaderComponent,
    ImgComponent,
    HeadingComponent,
  },
  data: () => {
    return {
      // aboutImgSm,
      aboutImgMd,
    };
  },
};
</script>

<style lang="scss" module>
.web-mb-about-section {
  &__row {
    display: flex;
    flex-direction: column-reverse;
    background-color: #fff;
    border-radius: 1.5rem;
  }

  &__col {
    flex: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
    padding: 1.5rem 1rem;
  }

  &__img-wrap {
    flex: 0;
  }

  &__link-btn {
    width: 100%;
  }

  &__link-btn:link,
  &__link-btn:visited {
    color: #ffffff;
    padding: 0.75rem 3rem;
    background-color: #b51ace;
    border-radius: 3rem;
    font-weight: 600;
    transition: background-color 0.15s ease-in-out;
  }

  &__link-btn:hover,
  &__link-btn:focus,
  &__link-btn:active {
    background-color: #e127ff;
  }

  &__img-sm {
    display: block;
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    min-width: 100%;
  }

  &__img-md {
    display: none;
  }

  @media (min-width: $min-md) {
    &__row {
      border-radius: 2rem;
    }

    &__content {
      padding: 2rem;
    }

    &__link-btn {
      width: auto;
    }

    &__img-sm {
      display: none;
    }

    &__img-md {
      display: block;
      border-top-right-radius: 1.5rem;
      border-top-left-radius: 1.5rem;
      min-width: 100%;
    }
  }

  @media (min-width: $min-lg) {
    &__row {
      flex-direction: row;
      align-items: stretch;
    }

    &__content {
      text-align: initial;
      align-items: start;
      padding: 3rem 3rem;
      min-width: 50%;
      gap: 0.5rem;
      font-size: 1rem;
    }

    &__img-wrap {
      flex: 1;
      background-image: url(../../../assets/images/site/about-img-md.jpg);
      background-size: cover;
      background-position: bottom;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      min-width: 50%;
    }

    &__link-btn {
      margin-top: 1rem;
    }

    &__img-md {
      border-top-left-radius: 0;
      border-bottom-right-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
      height: 100%;
      display: none;
    }
  }

  @media (min-width: $min-xl) {
    &__content {
      font-size: 1em;
    }
  }
}
</style>
