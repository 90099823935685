const createJuicyScript = () => {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "https://score.juicyscore.com/static/js.js";
  const x = document.getElementsByTagName("head")[0];
  x.appendChild(s);
};

const initJuicyPixel = () => {
  createJuicyScript();
  const apiKey = process.env?.VUE_APP_JUICY_API_KEY;

  window.juicyLabConfig = {
    nextButton: "your-next-button-id",
    stopPingButton: "your-ping-button-id",
    completeButton: " yoursubmit-button-id",
    apiKey,
  };
};

export default initJuicyPixel;
