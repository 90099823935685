<template>
  <SectionComponent>
    <ContainerComponent>
      <SectionHeaderComponent>
        <HeadingComponent level="h2" not-semantic
          >Features You’ll Love</HeadingComponent
        >
      </SectionHeaderComponent>
      <div :class="$style['web-mb-features-section__feature-wrap']">
        <div :class="$style['web-mb-features-section__feature-col']">
          <FeatureComponent
            v-for="item in features.slice(0, 3)"
            :key="item.id"
            :feature="item"
          />
        </div>
        <div :class="$style['web-mb-features-section__feature-img-wrap']"></div>
        <div :class="$style['web-mb-features-section__feature-col']">
          <FeatureComponent
            v-for="item in features.slice(3)"
            :key="item.id"
            :feature="item"
          />
        </div>
      </div>
    </ContainerComponent>
  </SectionComponent>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import SectionComponent from "@/components/ui/SectionComponent.vue";
import SectionHeaderComponent from "@/components/ui/SectionHeaderComponent.vue";
import HeadingComponent from "@/components/ui/elements/HeadingComponent.vue";

import FeatureComponent from "@/components/ui/FeatureComponent.vue";

import data from "@/data/features.json";

export default {
  name: "FeaturesSection",
  components: {
    ContainerComponent,
    SectionComponent,
    SectionHeaderComponent,
    HeadingComponent,
    FeatureComponent,
  },
  data: () => {
    return {
      features: data?.features,
    };
  },
};
</script>

<style lang="scss" module>
.web-mb-features-section {
  &__feature {
    &-wrap {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &-col {
      display: flex;
      gap: 2rem;
      flex-direction: column;
      flex: 1;
    }

    &-img-wrap {
      flex: 1;
      min-height: 300px;
      background-image: url(../../../assets/images/site/phone.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 -1rem 0 -1rem;
      filter: drop-shadow(rgb(153, 153, 153) 8px 24px 16px);
    }
  }

  @media (min-width: $min-md) {
    &__feature {
      &-img-wrap {
        min-height: 400px;
        background-size: contain;
      }
    }
  }

  @media (min-width: $min-lg) {
    &__feature {
      &-wrap {
        flex-direction: row;
        gap: 0;
      }

      &-img-wrap {
        min-height: 0;
      }
    }
  }
}
</style>
