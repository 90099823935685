<template>
  <a
    :class="$style['web-mb-button-link']"
    :href="href"
    :target="target"
    :style="compStyle"
    ><slot></slot
  ></a>
</template>

<script>
export default {
  name: "ButtonLinkComponent",
  props: {
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    bgColor: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    compStyle() {
      return {
        "background-color": this.bgColor,
        color: this.color,
      };
    },
  },
};
</script>

<style lang="scss" module>
.web-mb-button-link {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  background-color: #043ad9;
  border-radius: 2rem;
  font-weight: 600;
  min-width: 160px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
  }

  @media (min-width: $min-md) {
    & {
      width: auto;
    }
  }
}
</style>
