const promiseWithTimeout = (ms, promise) => {
  let timeoutPid;
  const timeout = new Promise((resolve, reject) => {
    timeoutPid = setTimeout(() => {
      reject(`Timed out after ${ms} ms.`);
    }, ms);
  });
  
  return Promise.race([
    promise,
    timeout
  ]).finally(() => {
    if (timeoutPid) {
      clearTimeout(timeoutPid);
    }
  });
}

export default promiseWithTimeout;
