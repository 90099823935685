var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SectionComponent',[_c('ContainerComponent',[_c('BannerPanelComponent',[_c('div',{class:_vm.$style['web-mb-banner-section__row']},[_c('div',{class:[
            _vm.$style['web-mb-banner-section__col'],
            _vm.$style['web-mb-banner-section__content'],
          ]},[_c('div',[_c('HeadingComponent',{attrs:{"level":"h2","reset-style":""}},[_c('div',{class:_vm.$style['web-mb-banner-section__header']},[_vm._v(" Download VIVA ")]),_c('div',{class:_vm.$style['web-mb-banner-section__header-descr']},[_vm._v(" Your Personal Loan App ")])]),_c('p',[_vm._v(" Instantly withdraw money from your credit limit of up to ₹2,00,000! No paperwork, ever ")])],1),_c('div',{class:_vm.$style['web-mb-banner-section__download-wrap']},[_c('div',{class:_vm.$style['web-mb-banner-section__qr-code-wrap']},[_c('ImgComponent',{attrs:{"src":_vm.qrCodeImg,"img-style":{
                  width: '128px',
                  height: '128px',
                  borderRadius: '0.5rem',
                },"alt":"QR code to download the VIVA Money App"}})],1),_c('div',{class:_vm.$style['web-mb-banner-section__download-links-wrap']},[_c('a',{attrs:{"href":"https://vivam.onelink.me/0rtl/zw1ld90q","target":"_blank"}},[_c('ImgComponent',{attrs:{"src":_vm.googlePlayImg,"img-style":{ width: 'auto', height: '42px' },"alt":"Download the VIVA app from Google Play Store"}})],1),_c('a',{attrs:{"href":"https://vivam.onelink.me/0rtl/zw1ld90q","target":"_blank"}},[_c('ImgComponent',{attrs:{"src":_vm.appStoreImg,"img-style":{ width: 'auto', height: '42px' },"alt":"Download the VIVA App from Apple App Store"}})],1)])])]),_c('div',{class:[
            _vm.$style['web-mb-banner-section__col'],
            _vm.$style['web-mb-banner-section__img-wrap'],
          ]},[_c('ImgComponent',{attrs:{"src":_vm.img,"img-style":{
              width: '70%',
              height: 'auto',
              filter: 'drop-shadow(-8px 8px 10px #222)',
              position: 'absolute',
            },"alt":"Download VIVA Money - India’s 1st Personal Line of Credit App to apply for Interest Free Loans"}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }