<template>
  <ModalComponent :is-open="isOpen" @close="close">
    <div slot="header">Get the app</div>
    <div slot="body">
      <div :class="$style['web-mb-get-app-modal__qr-code-wrap']">
        <div :class="$style['web-mb-get-app-modal__qr-code']">
          <ImgComponent
            :src="qrCodeImg"
            :img-style="{
              width: '168px',
              height: '168px',
            }"
            alt="A QR code"
          />
        </div>
        <div :class="$style['web-mb-get-app-modal__qr-code-text']">
          <ul>
            <li>Open your phone camera <br /> and point it at the QR code</li>
            <li>Alternatively, download any <br /> QR code scanner to scan.</li>
          </ul>
        </div>
      </div>
      <div :class="$style['web-mb-get-app-modal__download-wrap']">
        <a
          href="https://apps.apple.com/in/app/viva-money-credit-line-app/id6449091018"
          target="_blank"
        >
          <ImgComponent
            :src="appstoreImg"
            :img-style="{ width: 'auto', height: '56px' }"
            alt="VIVA Money IOS App from Apple App Store"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.vivamoney"
          target="_blank"
        >
          <ImgComponent
            :src="googleplayImg"
            :img-style="{ width: 'auto', height: '56px' }"
            alt="VIVA Money Android App from Play Store"
          />
        </a>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
import ImgComponent from "@/components/ui/ImgComponent.vue";
import ModalComponent from "@/components/pakistan/ui/ModalComponent.vue";

import appstoreImg from "@/assets/images/pakistan/employees/employees-hero-app-store-img.svg";
import googleplayImg from "@/assets/images/pakistan/employees/employees-hero-google-play-img.svg";
import qrCodeImg from "@/assets/images/pakistan/contact-us/qr-code.png";

export default {
  name: "GetAppModalComponent",
  components: {
    ImgComponent,
    ModalComponent,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      appstoreImg,
      googleplayImg,
      qrCodeImg,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" module>
.web-mb-get-app-modal {
  &__download-wrap {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3.5rem 0;
    align-items: center;
  }

  &__qr-code-wrap {
    display: none;
  }

  &__qr-code {
    width: 100%;
    flex: 1;
  }

  &__qr-code-text {
    flex: 1;
    font-size: 0.938rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: $min-lg) {
    &__download-wrap {
      display: none;
    }

    &__qr-code-wrap {
      display: flex;
      padding: 2rem 0;
    }
  }
}
</style>
